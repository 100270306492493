import React, { useState } from 'react';
import axios from 'axios';
import { Card, Button, Form, Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage/BackgroundImage.js';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentAuth/SubmitLogin',
        {
          username: username,
          password: password
        },
        {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Content-Type': 'application/json; charset=utf-8',
            'Expires': -1
          }
        }
      );
      const token = response.data.token;
      localStorage.setItem('ViribuzAccessToken', token);
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('isAdmin', response.data.isAdmin);
      Swal.fire({
        title: 'Welcome!',
        icon: 'success',
        text: 'You\'ve successfully logged in!',
      });
      navigate('/main');
    } catch (error) {
      Swal.fire({
        title: 'Login failed',
        icon: 'error',
        text: 'Login failed. Please try again.',
      });
    }
  };

  return (
    <div>
      <BackgroundImage />
      <Container style={{ position: 'relative', zIndex: 2001 }}>
        <Row className="justify-content-center mt-3">
          <Col md={4}>
            <Card className="shadow-lg" style={{ backgroundColor: 'white', borderColor: 'none', marginTop: '10rem', marginLeft: '100%', marginRight: '100%' }}>
              <Card.Body>
                <h2 className="text-center">Login</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formUsername">
                    <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPassword">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <div className="password-reset-link">
                    <Link to="/passwordreset">Forgot your password?</Link>
                  </div>
                  <Button variant="primary" type="submit" block className="login-button">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Login
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <span>Don't have an account?{' '}
                    <span className="text-primary">
                      <Link to="/register">Sign up</Link>
                    </span>
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
