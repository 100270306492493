import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import SideNav from "./components/SideNav/SideNav.js";

import Login from "./components/Login/Login.js";
import Logout from "./components/Logout/Logout.js";
import Register from "./components/Register/Register.js";
// import Error from "./pages/Error.js";
import PasswordReset from "./components/PasswordReset/PasswordReset.js";

import Main from "./pages/Main.js";
import Coverage from "./pages/Coverage.js";
import Database from "./pages/Database.js";
import Report from "./pages/Report.js";
import Schedule from "./pages/Schedule.js";
import Retainer from "./pages/Retainer.js";

import './App.css';

const routes = [
  { path: "/main", brandText: "Main Screen" },
  { path: "/coverage", brandText: "Waterfall Coverage Schedule" },
  { path: "/database", brandText: "Agent Data Base" },
  { path: "/report", brandText: "Agent Report Form" },
  { path: "/schedule", brandText: "Agent Schedule Form" },
  { path: "/retainer", brandText: "Retainer Tracker Sheet" },
];

function App() {
  return (
    <Router>
      <Row>
        <Col sm={3} md={2} className="sidebar">
          <SideNav />
        </Col>
        <Col>
          <Container fluid>
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route path="/passwordreset" element={<PasswordReset />} />
              <Route path="/register" element={<Register />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/main" element={<Main />} />
              <Route path="/coverage" element={<Coverage />} />
              <Route path="/database" element={<Database />} />
              <Route path="/report" element={<Report />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/retainer" element={<Retainer />} />       
            </Routes>
          </Container>
        </Col>
      </Row>
    </Router>
  );
}

export default App;
