import React, { useState, lazy, Suspense } from 'react';
import Select from 'react-select';

const NewTemplate = lazy(() => import('./CreateNewTemplate.js'));
const UpdateTemplate = lazy(() => import('./UpdateTemplate.js'));

const MainAssignmentTemplate = () => {
  const [selectedTemplate, setSelectedTemplate] = useState({ label: 'New Template', value: 'New Template' });

  const templateOptions = [
    { value: 'New Template', label: 'New Template' },
    { value: 'Update Template', label: 'Search Template' }
  ];

  const handleTemplateSelect = (selectedOption) => {
    setSelectedTemplate(selectedOption); 
  };

  return (
    <>
      <div style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '1rem', width: '280px' }}>
        <Select
          value={selectedTemplate}
          onChange={handleTemplateSelect}
          options={templateOptions}
          isSearchable={false} 
          styles={{
            control: (provided) => ({
              ...provided,
              border: '1px solid #cbcbcb',
              borderRadius: '10px',
              padding: '5px',
              cursor: 'pointer',
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 999, 
            }),
            singleValue: (provided) => ({
              ...provided,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '250px',
            }),
          }}
        />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {selectedTemplate.value === 'New Template' ? <NewTemplate /> : <UpdateTemplate />}
      </Suspense>
    </>
  );
};

export default MainAssignmentTemplate;
