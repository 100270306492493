import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import axios from 'axios';
import AgentsList from '../AgentsList.js';
import Swal from 'sweetalert2';

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

localizer.formats = {
  ...localizer.formats,
  timeGutterFormat: 'hh:mm A',
  eventTimeRangeFormat: ({ start, end }) => {
    const format = 'hh:mm A';
    const startTime = moment(start).format(format);
    const endTime = moment(end).format(format);
    const formattedStartTime = startTime === '12:00 AM' ? '' : startTime;
    const formattedEndTime = endTime === '12:00 AM' ? '' : endTime;

    return `${formattedStartTime} - ${formattedEndTime}`;
  },
};

const CoverageWaterfallDay = ({ onAllCampaignClick, readOnly, selectedCampaignId, selectedFirmId }) => {
  const [events, setEvents] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const scrollToTime = moment().startOf('day').add(8, 'hours').toDate();

  useEffect(() => {
    if (selectedAgentId) {
      fetchData(selectedAgentId);
    }
  }, [selectedAgentId, selectedCampaignId, selectedFirmId]);

  const fetchData = async (agentId, startDate, endDate) => {
    try {
      const campaignId = selectedCampaignId ?? 0;
      const firmId = selectedFirmId ?? 0;

      const adjustedStartDate = new Date(startDate);
      adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      const formattedStartDate = adjustedStartDate.toISOString().split('T')[0];
      const formattedEndDate = adjustedEndDate.toISOString().split('T')[0];

      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetSchedulesByAgentWeek?agentId=${agentId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&campaignId=${campaignId}&firmId=${firmId}`);

      const data = response.data.data;
      const newEvents = data.map(event => {
        const startTime = moment(event.StartTime, 'h:mm A').toDate();
        const endTime = moment(event.EndTime, 'h:mm A').toDate();

        const startDate = new Date(event.ScheduleDate);
        const endDate = new Date(event.ScheduleDate);

        startDate.setHours(startTime.getHours(), startTime.getMinutes());
        endDate.setHours(endTime.getHours(), endTime.getMinutes());

        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);

        return {
          id: event.Id,
          AgentName: event.AgentName,
          CampaignName: event.CampaignName,
          start: startDate,
          end: endDate,
          color: getColorByCampaign(event.CampaignName),
        };
      });

      setEvents(newEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAgentClick = (id) => {
    setSelectedAgentId(id);
  };

  const handleSelect = (slotInfo) => {
    setSelectedSlot({
      start: moment(slotInfo.start).toDate(),
      end: moment(slotInfo.end).toDate(),
    });
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleNavigate = (date) => {
    const adjustedDate = moment(date).subtract(1, 'day').toDate();

    const startDate = moment(adjustedDate).startOf('day').format('YYYY-MM-DD');
    const endDate = moment(adjustedDate).endOf('day').format('YYYY-MM-DD');
    fetchData(selectedAgentId, startDate, endDate);
  };

  const getColorByCampaign = (campaignName) => {
    switch (campaignName) {
      case 'APAP':
        return '#FEF789';
      case 'B2B':
        return '#CDA7ED';
      case 'CAMP':
        return '#FECDCA';
      case 'Camp Lejeune':
        return '#FECDCA';
      case 'NEC':
        return '#B2DDFF';
      case 'MVA':
        return '#ABEFC6';
      case 'TALC':
        return '#F2D5AE';
      default:
        return '#F3F8FF';
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const darkenedColor = darkenColor(event.color, 20);

    return {
      style: {
        backgroundColor: event.color,
        color: 'gray',
        borderLeft: `5px solid ${darkenedColor}`,
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
    };
  };

  const darkenColor = (color, percent) => {
    let num = parseInt(color.replace("#",""), 16),
        amt = Math.round(1.55 * percent),
        R = (num >> 16) - amt,
        G = (num >> 8 & 0x00FF) - amt,
        B = (num & 0x0000FF) - amt;
    return "#" + (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
    ).toString(16).slice(1);
  };

  const EventComponent = ({ event }) => (
    <div>
      <div>Eastern Time</div>
      <div>{event.AgentName}</div>
      <div style={{ fontSize: 'smaller', color: 'gray' }}>{event.CampaignName}</div>
    </div>
  );

  return (
    <Container fluid style={{ marginTop: '2rem', marginBottom: '3rem', marginLeft: '3rem' }}>
      <Row>
        <Col md={9}>
          <div style={{ position: 'relative' }}>
            <button className="btn btn-primary" onClick={onAllCampaignClick} style={{ position: 'absolute', top: 0, left: '15rem', height: '32px', display: 'flex', alignItems: 'center' }}>Week Calendar</button>
            <Container fluid className="WaterFallContainer">
              <Calendar
                localizer={localizer}
                events={events}
                defaultView="day"
                views={['day']}
                selectable={!readOnly}
                onSelectSlot={handleSelect}
                onSelectEvent={handleEventClick}
                style={{ height: 750 }}
                eventPropGetter={eventStyleGetter}
                onNavigate={handleNavigate}
                components={{
                  event: EventComponent,
                }}
                scrollToTime={scrollToTime}
              />
            </Container>
          </div>
        </Col>
        <Col md={3}>
          <AgentsList onAgentClick={handleAgentClick} readOnly={readOnly} />
        </Col>
      </Row>
    </Container>
  );
};

export default CoverageWaterfallDay;
