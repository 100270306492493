import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const User = ({ navigate, isCollapsed }) => {
  const [user, setUser] = useState(null);
  const [iconClassName, setIconClassName] = useState('green_icon');

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIconClassName('transparent');
    }, 50000);

    return () => clearTimeout(timer);
  }, []);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');
      const storedId = localStorage.getItem('id');

      if (!token) {
        throw new Error('Access token not found in localStorage');
      }

      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: {
          'ViribuzAccessToken': token
        }
      });

      const foundUser = response.data.data.find(u => u.id === parseInt(storedId));
      setUser(foundUser);

    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/logout');
  };

  const handleRefreshUserInfo = () => {
    fetchUser();
  };

  return (
    <div
      className="user-info"
      style={{
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        cursor: 'pointer',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
      onMouseEnter={handleRefreshUserInfo}
      onClick={handleLogout}
    >
      <div
        className="image_outer_container"
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          width: '40px',
          height: '43px',
          borderRadius: '50%',
          position: 'relative',
        }}
      >
        <div
          className={iconClassName}
          style={{
            backgroundColor: iconClassName === 'green_icon' ? '#4cd137' : 'transparent',
            position: 'absolute',
            left: '25px',
            bottom: '8px',
            height: '12px',
            width: '12px',
            border: `2px solid ${iconClassName === 'green_icon' ? 'white' : 'transparent'}`,
            borderRadius: '50%',
          }}
        ></div>
          <img
            src="https://w7.pngwing.com/pngs/893/926/png-transparent-silhouette-user-icon-profile-silhouette-silhouette-avatar-profile-silhouette-thumbnail.png"
            alt="User Avatar"
            style={{
              height: '35px',
              width: '34px',
              borderRadius: '50%',
              border: '3px solid white',
            }}
          />
      </div>
      {!isCollapsed && user && (
        <div style={{ marginLeft: '10px' }}>
          <span style={{ display: 'block' }}>{`${user.firstName} ${user.lastName}`}</span>
          <span style={{ display: 'block', fontSize: '0.8rem' }}>{user.email}</span>
        </div>
      )}
      {!isCollapsed && <FontAwesomeIcon icon={faSignOutAlt} style={{ marginLeft: '3rem' }} />}
    </div>
  );
};

export default User;
