import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar.js";
import ScheduleAgentScheduleAdmin from "../components/ScheduleDailyAssignments/AgentSchedule/ScheduleAgentScheduleAdmin.js";
import ScheduleAgentScheduleAgent from "../components/ScheduleDailyAssignments/AgentSchedule/ScheduleAgentScheduleAgent.js";

import ScheduleDailyAssignmentAdmin from "../components/ScheduleDailyAssignments/DailyAssignment/ScheduleDailyAssignmentAdmin.js";
import ScheduleDailyAssignmentAgent from "../components/ScheduleDailyAssignments/DailyAssignment/ScheduleDailyAssignmentAgent.js";

import ScheduleDateKey from "../components/ScheduleDailyAssignments/ScheduleDateKey.js";

import MainAssignmentTemplate from "../components/ScheduleDailyAssignments/AssignmentTemplate/MainAssignmentTemplate.js";

import CoverageScheduleLog from "../components/CoverageScheduleLog/CoverageScheduleLog.js";
import CoverageScheduleApproval from "../components/CoverageScheduleApproval/CoverageScheduleApproval.js";

import ScheduleAgentScheduleGS from "../components/ScheduleDailyAssignments/GoogleSheet/ScheduleAgentScheduleGS.js";

function Schedule() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState("AgentSchedule");

  useEffect(() => {
    const isAdminValue = localStorage.getItem("isAdmin");
    setIsAdmin(isAdminValue === "true");
  }, []);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "AgentSchedule":
        return isAdmin ? <ScheduleAgentScheduleAdmin /> : <ScheduleAgentScheduleAgent />;
      case "DailyAssignment":
        return isAdmin ? <ScheduleDailyAssignmentAdmin /> : <ScheduleDailyAssignmentAgent />;
      case "DateKey":
        return <ScheduleDateKey />;
      case "AssignmentTemplate":
        return (
          isAdmin && (
            <>
              <MainAssignmentTemplate />
            </>
          )
        );
      case "ScheduleLog":
        return isAdmin && <CoverageScheduleLog />;
      case "ScheduleApproval":
        return isAdmin && <CoverageScheduleApproval />;
      case "GoogleSheet":
        return <ScheduleAgentScheduleGS />;
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar isAdmin={isAdmin} activeTab={activeTab} onTabSelect={handleTabSelect} />
      {renderContent()}
    </>
  );
}

export default Schedule;
