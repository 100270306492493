import React, { useState } from 'react';
import MainAgentTablePagination from './MainAgentTablePagination';
import MainAgentTableDropdown from './MainAgentTableDropdown';
import MainAgentTableSort from './MainAgentTableSort';

const ParentComponent = () => {
  const [selectedAgent, setSelectedAgent] = useState('');

  const handleAgentFilterChange = (selectedAgent) => {
    setSelectedAgent(selectedAgent);
  };

  return (
    <div style={{ width: '100%', marginLeft: '1rem' }}>
      {selectedAgent ? (
        <MainAgentTableDropdown
          selectedAgent={selectedAgent}
          onAgentFilterChange={handleAgentFilterChange}
        />
      ) : (
        <MainAgentTablePagination
          selectedAgent={selectedAgent}
          onAgentFilterChange={handleAgentFilterChange}
        />
      )}
    </div>
  );
};

export default ParentComponent;
