import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import Agents from './Agents.js';
import Swal from 'sweetalert2';
import { MultiSelect } from "react-multi-select-component";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/themes/base/all.css';
import "./reportform.css";

const ReportFormAdmin = () => {
  const [agents, setAgents] = useState([]);
  const [formData, setFormData] = useState({
    DateRetained: '',
    agentId: '',
    FirmIdsStr: '',
    CampaignIdsStr: '',
    TotalWorkedHours: '',
    ScheduleShift: 'am',
    TotalPhoneCallsMade: '',
    TotalMessagesSent: '',
    TotalEmailsSent: '',
    TotalContactReachOut: '',
    TotalDisqualifiedLeads: '',
    TotalAppointmentsSet: '',
    TotalRetainersAcquired: '',    
    TotalDocumentsSent: '',
    TotalShowedAppointments: '',
    RetainersSet: '',
    TotalLiveTransferSent: '',
  });

  const [records, setRecords] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [firms, setFirms] = useState([]);
  const [selectedFirms, setSelectedFirms] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selecteDateRetained, setSelecteDateRetained] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    fetchCampaigns();
    fetchFirms();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      DateRetained: selecteDateRetained,
    });
  }, [selecteDateRetained]);

  useEffect(() => {
    setIsSubmitDisabled(!formData.agentId || !formData.DateRetained);
  }, [formData.agentId, formData.DateRetained]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const { campaigns } = response.data;
      const sortedCampaigns = campaigns.sort((a, b) => a.name.localeCompare(b.name));
      setCampaigns(sortedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const { firms } = response.data;
      const sortedFirms = firms.sort((a, b) => a.name.localeCompare(b.name));
      setFirms(sortedFirms);
    } catch (error) {
      console.error("Error fetching firms:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeV2 = (e) => {
    const { name, value } = e.target;
    if (isNaN(value) || isNaN(parseFloat(value))) {
      if (value === '') {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      if (value > -1) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
  }

  const handleFirmChange = (e) => {
    setSelectedFirms(e);
    let idsStr = e.map(x => x.value).join(',');
    setFormData({ ...formData, FirmIdsStr: idsStr });
  }

  const handleCampaignChange = (e) => {
    setSelectedCampaigns(e);
    let idsStr = e.map(x => x.value).join(',');
    setFormData({ ...formData, CampaignIdsStr: idsStr });
  }

  const handleCancel = () => {
    setFormData({
      DateRetained: '',
      agentId: '',
      FirmIdsStr: '',
      CampaignIdsStr: '',
      TotalWorkedHours: '',
      ScheduleShift: 'am',
      TotalPhoneCallsMade: '',
      TotalMessagesSent: '',
      TotalEmailsSent: '',
      TotalDisqualifiedLeads: '',
      TotalLiveTransferSent: '',
      TotalAppointmentsSet: '',
      TotalRetainersAcquired: '',
      TotalDocumentsSent: '',
      TotalShowedAppointments: '',
      RetainersSet: '',
      TotalContactReachOut: '',
    });
    setSelectedFirms([]);
    setSelectedCampaigns([]);
  };

  const handleSave = async () => {
    if (!formData.agentId) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Agent ID is required. Please select an agent or refresh the page.',
      }).then(() => {
        window.location.reload();
      });
      return;
    }

    if (!formData.DateRetained) {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Date Retained is required. Please select a date or refresh the page.',
      });
      return;
    }

    const currentDate = new Date().toLocaleString();
    const localStorageId = localStorage.getItem('id');

    try {
      const token = localStorage.getItem('ViribuzAccessToken');

      if (!token) {
        throw new Error('Access token not found in localStorage');
      }

      const response = await axios.post(
        'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentReport/PostReportSubmission',
        new URLSearchParams(formData),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      console.log('Response Status:', response.status);
      console.log('Response Data:', response.data);

      if (response.status === 200 && response.data.success) {
        Swal.fire({
          title: 'Report submitted',
          icon: 'success',
          text: `Your report has been submitted successfully! Status Code: ${response.status}`,
        });

        const newRecord = { ...formData, saveDate: currentDate, statusCode: response.status, submitterId: localStorageId };
        setRecords([...records, newRecord]);
        handleCancel();

        console.log('Report submitted successfully:', response.data);
      } else {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: 'An error occurred while submitting the report. Please try again later.',
        });
      }
    } catch (error) {
      console.error('Error submitting report:', error);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'An error occurred while submitting the report. Please try again later.',
      });
    }
  };



  const applyDatePicker = () => {
    $('#DateRetained').datepicker({
      dateFormat: 'mm/dd/yy',
      onSelect: function (input) {
        setSelecteDateRetained(input);
      }
    });
  }
  applyDatePicker();

  const firmOptions = firms.map((firm) => ({ label: firm.name, value: firm.id }));
  const campaignOptions = campaigns.map((campaign) => ({ label: campaign.name, value: campaign.id }));

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <Container className="container-fluid mt-5" style={{ color: 'inherit' }}>
        <Row className="justify-content-end">
          {/* First Card - Date, Name, Campaign */}
          <Col md={6}>
            <Card className="mb-4 bg-white">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Date:</Form.Label>
                  <input id="DateRetained" name="DateRetained" value={formData.DateRetained} className="form-control" type="text" />
                </Form.Group>
                <Agents onAgentChange={(agentId) => setFormData({ ...formData, agentId })} />
                <Form.Group className="mb-3">
                  <Form.Label>Firm:</Form.Label>
                  <MultiSelect options={firmOptions} value={selectedFirms} onChange={handleFirmChange} labelledBy="Select" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Campaign:</Form.Label>
                  <MultiSelect options={campaignOptions} value={selectedCampaigns} onChange={handleCampaignChange} labelledBy="Select" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Total Worked Hours:</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalWorkedHours"
                    value={formData.TotalWorkedHours}
                    onChange={handleChangeV2}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Schedule Shift:</Form.Label>
                  <Form.Control
                    as="select"
                    name="ScheduleShift"
                    value={formData.ScheduleShift}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  >
                    <option value="am">AM shift</option>
                    <option value="pm">PM shift</option>
                  </Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>


          <Col md={6}>
            <Card className="mb-4 bg-white">
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Calls</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalPhoneCallsMade"
                    value={formData.TotalPhoneCallsMade}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message Sent</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalMessagesSent"
                    value={formData.TotalMessagesSent}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email Sent</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalEmailsSent"
                    value={formData.TotalEmailsSent}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label># of Contact Reach Total</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalContactReachOut"
                    value={formData.TotalContactReachOut}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Disqualified</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalDisqualifiedLeads"
                    value={formData.TotalDisqualifiedLeads}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Appointment Set</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalAppointmentsSet"
                    value={formData.TotalAppointmentsSet}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Retainers</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalRetainersAcquired"
                    value={formData.TotalRetainersAcquired}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Documents Sent</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalDocumentsSent"
                    value={formData.TotalDocumentsSent}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Showed Appointments</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalShowedAppointments"
                    value={formData.TotalShowedAppointments}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Retainers Set</Form.Label>
                  <Form.Control
                    type="number"
                    name="RetainersSet"
                    value={formData.RetainersSet}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Live Transfers Sent</Form.Label>
                  <Form.Control
                    type="number"
                    name="TotalLiveTransferSent"
                    value={formData.TotalLiveTransferSent}
                    onChange={handleChange}
                    style={{ color: 'inherit' }}
                  />
                </Form.Group>

                <Row className="mb-3">
                  <Col>
                    <Button 
                      className="glow-button" 
                      style={{ border: '1px solid #cbcbcb', backgroundColor: 'white', color: 'black', marginLeft: '45%' }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>{' '}
                    <Button className="glow-button" onClick={handleSave} disabled={isSubmitDisabled} style={{ backgroundColor: '#7F56D9', color: 'white' }}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <ul>
              {records.map((record, index) => (
                <li key={index}>
                  {Object.keys(record).map((fieldName) => (
                    <div key={fieldName}>
                      <strong>{fieldName}:</strong> {record[fieldName]}
                    </div>
                  ))}
                  <div>
                    <strong>Submitter ID:</strong> {record.submitterId}
                  </div>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportFormAdmin;
