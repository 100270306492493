import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';

const Agents = ({ onAgentChange }) => {
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const token = localStorage.getItem('ViribuzAccessToken');

        if (!token) {
          throw new Error('Access token not found in localStorage');
        }

        const excludedEmails = [
          'admin@mail.com',
          'casper@mail.com',
          'agent@mail.com',
          'testagentx@agent.com',
          'testagenty@agent.com',
          'string',
          'rogeradmin@agent.com'
        ];

        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
          headers: {
            'ViribuzAccessToken': token
          }
        });

        const filteredAgents = response.data.data
          .filter(agent => !agent.isAdmin)
          .filter(agent => !excludedEmails.includes(agent.email));
        const sortedAgents = filteredAgents.sort((a, b) => a.firstName.localeCompare(b.firstName));


        setAgents(sortedAgents);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleAgentChange = (e) => {
    const agentId = e.target.value;
    setSelectedAgent(agentId);
    onAgentChange(agentId);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Form>
      <Form.Group controlId="agentDropdown">
        <Form.Label>Select Agent:</Form.Label>
        <Form.Control as="select" value={selectedAgent} onChange={handleAgentChange}>
          <option value="">Select an agent</option>
          {agents.map(agent => (
            <option key={agent.id} value={agent.id}>
              {agent.firstName} {agent.lastName}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

export default Agents;
