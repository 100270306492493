import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import moment from "moment";

moment.locale("en-US");

const AgentsList = ({ onAgentClick, onAgentSelect, currentWeek }) => {
  const [agent, setAgent] = useState(null);

  useEffect(() => {
    fetchAgent();
  }, [currentWeek]);

  useEffect(() => {
    fetchAgent();
  }, []);

  const fetchAgent = async () => {
    try {
      const accessToken = localStorage.getItem('ViribuzAccessToken');
      const storedId = localStorage.getItem('id');
      

      if (accessToken && storedId) {
        const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetAgentsHoursByWeek?startDate=${moment(currentWeek.startDate).format('YYYY-MM-DD')}&endDate=${moment(currentWeek.endDate).format('YYYY-MM-DD')}`, {
          headers: {
            'ViribuzAccessToken': accessToken,
            
          },
        });

        const agentData = response.data.data.find(agent => agent.id === parseInt(storedId));
        if (agentData && !agentData.isAdmin) {
          const modifiedAgent = {
            ...agentData,
            avatarUrl: 'https://w7.pngwing.com/pngs/893/926/png-transparent-silhouette-user-icon-profile-silhouette-silhouette-avatar-profile-silhouette-thumbnail.png',
          };

          setAgent(modifiedAgent);
          onAgentSelect(`${agentData.firstName} ${agentData.lastName}`);
        }
      } else {
        console.error('Access token or agent id not found in localStorage.');
      }
    } catch (error) {
      console.error('Error fetching agent:', error);
    }
  };

  const handleAgentClick = (agent) => {
    onAgentClick(`${agent.firstName} ${agent.lastName}`);
    console.log('Clicked on agent:', agent.id);
  };

  const handleRefreshUserInfo = () => {
    console.log('Refresh user info');
  };

  const handleLogout = () => {
    console.log('Logout');
  };

  return (
    <Card className="agents-list-container" style={{ width: '90%', height: '46rem', overflowY: 'auto', margin: '20px auto', marginLeft: '10px' }}>
      <Card.Body>
        <Card.Title style={{ fontWeight: 'bold', fontSize: '25px' }}>Agent</Card.Title>
        <Card.Text style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>Schedule for this week</Card.Text>
        <div className="agents-list">
          <div className="agents-list-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p>Employee</p>
            <p>Hours</p>
          </div>
          {agent ? (
            <div 
              key={agent.id} 
              className="agent-item" 
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}
              onMouseEnter={handleRefreshUserInfo}
              onClick={() => handleAgentClick(agent)}
            >
              <div className="user-info" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div className="image_outer_container" style={{ position: 'relative', width: '40px', height: '43px', borderRadius: '50%', marginLeft: '8px' }}>

                  <img src={agent.avatarUrl} alt="User Avatar" style={{ height: '35px', width: '34px', borderRadius: '50%', border: '3px solid white' }} />
                </div>
                <div style={{ marginLeft: '10px' }}>
                  <p style={{ margin: '0', marginBottom: '5px' }}>{agent.firstName} {agent.lastName}</p>
                  <p style={{ margin: '0' }}>{agent.email}</p>
                </div>
              </div>
                <div style={{ maginRight: '2px' }}>
                  <p style={{ margin: '0', marginBottom: '5px' }}>hours</p>
                  <p style={{ margin: '0' }}>{agent.totalHours}</p>
                </div>
            </div>
          ) : (
            <p>No agent found</p>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AgentsList;