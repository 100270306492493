import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar.js";
import MainCalendar from "../components/MainCalendar/MainCalendar.js";
// import MainAgentTable from "../components/MainAgentTable/MainAgentTable.js";
// import MainPipelineDropdown from "../components/MainPipeline/MainPipelineDropdown.js";
// import MainPipelineAdmin from "../components/MainPipeline/AdminView/MainPipeline.js";
// import MainPipeline2Admin from "../components/MainPipeline/AdminView/MainPipeline2.js";
// import MainPipeline from "../components/MainPipeline/AgentView/MainPipeline.js";
// import MainPipeline2 from "../components/MainPipeline/AgentView/MainPipeline2.js";


function Main({ pipelineData }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    const isAdminValue = localStorage.getItem("isAdmin");
    setIsAdmin(isAdminValue === "true");
  }, []);

  const handleCampaignSelect = (campaignId) => {
    setSelectedCampaign(campaignId);
  };

  const handleFirmSelect = (firmId) => {
    setSelectedFirm(firmId);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      <Navbar
        onCampaignSelect={handleCampaignSelect}
        onFirmSelect={handleFirmSelect}
      />
      <MainCalendar selectedCampaign={selectedCampaign} selectedFirm={selectedFirm} />


{/*      <MainPipelineDropdown onSelect={handleOptionSelect} />
      <Row>
        {isAdmin ? (
          selectedOption.dateRange === "Day 1-3" ||
          selectedOption.dateRange === "Days 4-7" ? (
            <MainPipelineAdmin
              campaignId={selectedOption.campaignId}
              dateRange={selectedOption.dateRange}
            />
          ) : (
            <MainPipeline2Admin
              campaignId={selectedOption.campaignId}
              dateRange={selectedOption.dateRange}
            />
          )
        ) : (
          selectedOption.dateRange === "Day 1-3" ||
          selectedOption.dateRange === "Days 4-7" ? (
            <MainPipeline
              campaignId={selectedOption.campaignId}
              dateRange={selectedOption.dateRange}
            />
          ) : (
            <MainPipeline2
              campaignId={selectedOption.campaignId}
              dateRange={selectedOption.dateRange}
            />
          )
        )}
      </Row>*/}
    </>
  );
}

export default Main;
