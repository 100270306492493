// LockComponent.js

import React from 'react';
import ChillNoAccess from "../../assets/icons/chill-no-access.gif";

const LockComponent = () => {
  return (
    <div className="container-fluid" style={{ paddingLeft: '30%', paddingRight: '30%', marginTop: '2rem', marginBottom: '3rem' }}>
      <div className="card bg-white card-with-box-shadow">
        <div className="card-body" style={{ textAlign: 'center' }}>
          <h1 style={{ color: '#a2a9b2' }}>Need permission to access.</h1>
          <img src={ChillNoAccess} />
        </div>
      </div>
    </div>
  );
};

export default LockComponent;

