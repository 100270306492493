import React, { useState, useEffect } from "react";

import { Container, Row } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar.js";
// import ReportForm from "../components/ReportForm/ReportForm.js";
import ReportFormAdmin from "../components/ReportForm/ReportFormAdmin.js";
import LockComponent from "../components/LockComponent/LockComponent.js";

function Report() {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const isAdminValue = localStorage.getItem("isAdmin");
    setIsAdmin(isAdminValue === "true");
  }, []);

  return (
    <>
      <Navbar />
      {/*{isAdmin ? <ReportFormAdmin /> : <ReportForm />}*/}
      {isAdmin ? <ReportFormAdmin /> : <LockComponent />}
      {/*{isAdmin && <ReportFormAdmin />}*/}
    </>
  );
}

export default Report;