import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar.js";
import DatabaseNavigation from "../components/DatabaseChart/DatabaseNavigation.js";
import DatabaseOutput from "../components/DatabaseChart/DatabaseOutput.js";
import DatabaseRetainersTotal from "../components/DatabaseChart/DatabaseRetainersTotal.js";
import DatabaseAnalytics from "../components/DatabaseAnalytics/DatabaseAnalytics.js";
import MainAgentTable from "../components/MainAgentTable/MainAgentTable.js";
import axios from 'axios';

function Database() {
  const [selectedAgent, setSelectedAgent] = useState('0');
  const [selectedCampaign, setSelectedCampaign] = useState('0');
  const [timeFrame, setTimeFrame] = useState('weeks');
  const [agents, setAgents] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const handleAgentChange = (agentId) => {
    setSelectedAgent(agentId);
  };

  const handleCampaignChange = (campaignId) => {
    setSelectedCampaign(campaignId);
  };

  const handleTimeFrameChange = (frame) => {
    setTimeFrame(frame);
  };

  useEffect(() => {
    fetchAgents();
    fetchCampaigns();
  }, []);

  const fetchAgents = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');
      if (!token) {
        console.error('No access token found in localStorage');
        return;
      }

      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: {
          'ViribuzAccessToken': token,
        }
      });

      const filteredAgents = response.data.data.filter(agent => !agent.isAdmin);
      setAgents(filteredAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const sortedCampaigns = response.data.campaigns.sort((a, b) => a.name.localeCompare(b.name));
      const allCampaignOption = { id: '0', name: 'All Campaigns' };
      setCampaigns([allCampaignOption, ...sortedCampaigns]);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  return (
    <>
      
      <Navbar />
      <div style={{ marginLeft: '5rem', marginRight: '1rem' }}>
      <DatabaseNavigation
        agents={agents}
        campaigns={campaigns}
        onAgentChange={handleAgentChange}
        onCampaignChange={handleCampaignChange}
        onTimeFrameChange={handleTimeFrameChange}
      />

      <Row>
        <Col md={6}>
          <Card style={{ height: '580px' }}>
            <Card.Header>Database Output</Card.Header>
            <Card.Body>
              <DatabaseOutput 
                selectedAgent={selectedAgent} 
                selectedCampaign={selectedCampaign} 
                timeFrame={timeFrame}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ height: '580px' }}>
            <Card.Header>Database Retainers Total</Card.Header>
            <Card.Body>
              <DatabaseRetainersTotal 
                selectedAgent={selectedAgent} 
                selectedCampaign={selectedCampaign} 
                timeFrame={timeFrame}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      </div>
      <Row>
        <DatabaseAnalytics />
      </Row>
      <Row>
        <MainAgentTable />
      </Row>
    </>
  );
}

export default Database;
