import React, { useState, useEffect } from 'react';
import { Card, Table, Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { IoFilter } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { format } from 'date-fns';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const priorityColors = {
  'Sr. Nurse': '#fff9ea',
  'Nurse': '#ebffea',
};

const assignmentColors = {
  'New Leads': '#3b3b3b',
  'Appointments': '#a50603',
  'Yesterday': '#0b53a9',
  '2 Days Ago': '#5a3386',
  '3 Days Ago': '#ffc8aa',
  '4-7 Days Old': '#ffe5a0',
  '8-31 Days Old': '#bee1f5',
  '32-60 Days Old': '#c6dae1',
  '61-90 Days Old': '#b4b7bd',
  '91+ Days Old': '#215a6d',
};

const pipelineColors = {
  'N/A': '#3b3b3b',
  'Survey Submitted': '#b10200',
  'Persequi Verified': '#753800',
  'Manual F/U': '#463821',
  'Persequi Alert': '#10734b',
  'Unresponsive': '#0750a5',
  'Appt Booked': '#0b53a8',
  'Appt No Show': '#583083',
  'Docs Sent': '#c6dae0',
  'Non Responsive x2': '#ffe5a0',
  'Application': '#fed0c9',
  'In Contact': '#d4edbc',
};

const assignmentStatusColors = {
  'In Progress': '#bfe1f5',
  'Completed': '#d3edbd',
  'Not Completed': '#ffd0c9',
};

const ScheduleDailyAssignmentAgent = () => {
  const [agents, setAgents] = useState([]);
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [campaigns, setCampaigns] = useState([]); 
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [assignments, setAssignments] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [filterStatus, setFilterStatus] = useState('View All');

  const shiftOptions = [
    { value: 'A.M.', label: 'A.M.' },
    { value: 'P.M.', label: 'P.M.' }
  ];

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns', {
        headers: { 'Accept': 'application/json' }
      });
      
      const campaignOptions = response.data.campaigns.map(campaign => ({
        value: campaign.id,
        label: campaign.name,
      }));
      
      setCampaigns(campaignOptions);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };


  const fetchFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const firmOptions = response.data.firms.map(firm => ({
        value: firm.id,
        label: firm.name
      }));
      setFirms(firmOptions);
    } catch (error) {
      console.error('Error fetching firms:', error);
    }
  };

  const fetchAssignmentsByDate = async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDailyAssignmentV2/GetAssignmentsByDateList?date=${formattedDate}`, {
        headers: { 'Accept': 'application/json' }
      });

      const fetchedAssignments = response.data.data.map(item => {
        const campaignIds = item.CampaignIdStr.split(',').map(id => id.trim());
        const campaignLabels = campaignIds.map(id => {
          const campaign = campaigns.find(c => c.value.toString() === id);
          return campaign ? campaign.label : `Unknown (${id})`;
        }).join(', ');

        return {
          id: item.Id,
          priority: item.Priority,
          agent: item.AgentId,
          shift: item.ShiftType.replace(' Shift', ''),
          account: item.FirmId,
          campaign: campaignIds,
          campaignNames: campaignLabels,
          assignment: item.Assignment,
          pipelineStage: item.PipelineStage,
          assignmentStatus: item.AssignmentStatus,
        };
      });

      setAssignments(fetchedAssignments);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  useEffect(() => {
    fetchAssignmentsByDate(selectedDate);
  }, [selectedDate]);

  const filteredAssignments = assignments.filter(assignment => {
    if (filterStatus === 'View All') return true;
    return assignment.assignmentStatus === filterStatus;
  });


  useEffect(() => {
    fetchFirms();
  }, []);

  const handleRefresh = () => {
    fetchAssignmentsByDate(selectedDate);
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (campaigns.length > 0) { 
      fetchAssignmentsByDate(selectedDate);
    }
  }, [selectedDate, campaigns]);


  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const accessToken = localStorage.getItem('ViribuzAccessToken');
        const config = {
          method: 'get',
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents',
          headers: { 'ViribuzAccessToken': accessToken },
        };
        const response = await axios(config);
        const agentOptions = response.data.data.map(agent => ({
          value: agent.id,
          label: `${agent.firstName} ${agent.lastName}`,
        }));
        setAgents(agentOptions);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };
    fetchAgents();
  }, []);

  const handleAgentChange = (id, selectedAgent) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, agent: selectedAgent.value } : assignment
    ));
  };

  const handleCampaignChange = (id, selectedCampaigns) => {
    const selectedCampaignIds = selectedCampaigns.map(option => option.value);
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, campaign: selectedCampaignIds } : assignment
    ));
  };

  const handlePriorityChange = (id, newPriority) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, priority: newPriority } : assignment
    ));
  };

  const handlePipelineStageChange = (id, newStage) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, pipelineStage: newStage } : assignment
    ));
  };

  const handleAssignmentChange = (id, newAssignment) => {
    setAssignments(prevAssignments => prevAssignments.map(assignment =>
      assignment.id === id ? { ...assignment, assignment: newAssignment } : assignment
    ));
  };

  const handleAssignmentStatusChange = (id, newAssignmentStatus) => {
    setAssignments(assignments.map(assignment =>
      assignment.id === id ? { ...assignment, assignmentStatus: newAssignmentStatus } : assignment
    ));
  };

  const dayOfWeek = format(selectedDate, 'EEEE');

  return (
    <Container fluid style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '10rem' }}>
      <style>
        {`
          .custom-datepicker {
            border: none !important;
            box-shadow: none !important;
            text-align: center;
          }
          .datepicker-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .datepicker-container .react-datepicker-wrapper {
            width: 140px; 
          }
        `}
      </style>
      <Row className="justify-content-center">
        <div className="datepicker-container" style={{ border: '1px solid #cbcbcb', borderRadius: '10px', marginLeft: '1rem', padding: '5px', fontWeight: 'bold' }}>
          <CiCalendar style={{ fontSize: '1.5rem', marginRight: '-1rem' }} />
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="MMMM d, yyyy"
            className="form-control custom-datepicker"
            popperPlacement="bottom-start"
            style={{ margin: '1px' }}
          />
          <span style={{ marginLeft: '-1rem' }}>- {dayOfWeek}</span>
        </div>  
        <Col md={12}>
          <Card style={{ marginTop: '2rem', borderTop: '3px solid #7b50d5', color: 'black' }}>
            <Card.Body style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1rem' }}>
                  <h3 style={{ fontWeight: 'bold' }}>Daily Assignments</h3>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button variant="secondary" style={{ border: '1px solid #ccc', padding: '15px', marginRight: '0.5rem', borderRadius: '10px', display: 'flex', alignItems: 'center' }} onClick={handleRefresh}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                  </Button>
                </div>
              </div>

              <hr style={{ width: '100%', margin: '1rem 0', border: '0.5px solid #ced2d6' }} />

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>

                <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '10px', marginLeft: '1rem' }}>
                  <Button
                    variant="outline-primary"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'View All' ? '0px 0px 15px rgba(0, 123, 255, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('View All')}
                  >
                    View All
                  </Button>
                  <div style={{ height: '40px', width: '1px', backgroundColor: '#ccc' }} />
                  
                  <Button
                    variant="outline-success"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'Completed' ? '0px 0px 15px rgba(40, 167, 69, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('Completed')}
                  >
                    Completed
                  </Button>
                  <div style={{ height: '40px', width: '1px', backgroundColor: '#ccc' }} />
                  
                  <Button
                    variant="outline-warning"
                    style={{
                      borderRadius: '10px',
                      boxShadow: filterStatus === 'In Progress' ? '0px 0px 15px rgba(255, 193, 7, 0.6)' : 'none'
                    }}
                    onClick={() => setFilterStatus('In Progress')}
                  >
                    In Progress
                  </Button>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ border: '1px solid #ccc', padding: '5px', marginRight: '0.5rem', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem', color: '#a2a9b2' }} />
                    <input type="text" placeholder="Search" style={{ border: 'none', outline: 'none' }} />
                  </div>
                  <Button variant="outline-secondary" style={{ border: '1px solid #ccc', padding: '5px', marginRight: '1rem', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                    <IoFilter size="1.5em" />
                    <span style={{ paddingLeft: '5px' }}>Filters</span>
                  </Button>
                </div>
              </div>

              <hr style={{ width: '100%', margin: '1rem 0', border: '0.5px solid #ced2d6' }} />

              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Priority</th>
                    <th>Agent</th>
                    <th>Shift</th>
                    <th>Account</th>
                    <th>Campaign</th>
                    <th>Assignment</th>
                    <th>Pipeline Stage</th>
                    <th>Assignment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAssignments.length > 0 ? (
                    filteredAssignments.map((assignment) => (
                      <tr key={assignment.id}>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              disabled={true}
                              style={{
                                backgroundColor: priorityColors[assignment.priority] || '#d4edda',
                                borderRadius: '12px',
                                padding: '0.5rem 1rem',
                                fontSize: '0.7rem',
                                border: 'none',
                                boxShadow: 'none',
                                margin: '0.25rem',
                                display: 'inline-block',
                                textAlign: 'center',
                                color: '#000',
                              }}
                            >
                              {assignment.priority}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                              {Object.keys(priorityColors).map((priorityKey) => (
                                <Dropdown.Item
                                  key={priorityKey}
                                  onClick={() => handlePriorityChange(assignment.id, priorityKey)}
                                  style={{ backgroundColor: priorityColors[priorityKey], color: '#000' }}
                                >
                                  {priorityKey}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <Select
                            value={agents.find((option) => option.value === assignment.agent)}
                            isDisabled={true}
                            onChange={(selectedOption) => handleAgentChange(assignment.id, selectedOption)}
                            options={agents}
                            placeholder="Select Agent"
                          />
                        </td>
                        <td>
                          {/* Read-only Shift */}
                          <Select
                            value={shiftOptions.find(shift => shift.value === assignment.shift)} 
                            isDisabled={true}
                            options={shiftOptions} 
                            placeholder="Select shift"
                          />
                        </td>
                        <td>
                          {/* Read-only Account */}
                          <Select
                            value={firms.find(firm => firm.value === assignment.account)} 
                            isDisabled={true}
                            options={firms} 
                            placeholder="Select firm"
                          />
                        </td>
                        <td>
                          {/* Read-only Campaign */}
                          <Select
                            value={campaigns.filter(option => assignment.campaign.includes(option.value.toString()))}  
                            isMulti
                            isDisabled={true}
                            options={campaigns}
                            placeholder="Select Campaigns"
                          />
                        </td>
                        <td>
                          {/* Read-only Assignment */}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              disabled={true}
                              style={{
                                backgroundColor: assignmentColors[assignment.assignment] || '#d4edda',
                                borderRadius: '12px',
                                padding: '0.5rem 1rem',
                                fontSize: '0.7rem',
                                border: 'none',
                                boxShadow: 'none',
                                margin: '0.25rem',
                                display: 'inline-block',
                                textAlign: 'center',
                                color: '#fff',
                              }}
                            >
                              {assignment.assignment}
                            </Dropdown.Toggle>
                          </Dropdown>
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              style={{
                                backgroundColor: pipelineColors[assignment.pipelineStage] || '#d4edda',
                                borderRadius: '12px',
                                padding: '0.5rem 1rem',
                                fontSize: '0.7rem',
                                border: 'none',
                                boxShadow: 'none',
                                margin: '0.25rem',
                                display: 'inline-block',
                                textAlign: 'center',
                                color: '#fff',
                              }}
                            >
                              {assignment.pipelineStage}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                              {Object.keys(pipelineColors).map((pipelineKey) => (
                                <Dropdown.Item
                                  key={pipelineKey}
                                  onClick={() => handlePipelineStageChange(assignment.id, pipelineKey)}
                                  style={{ backgroundColor: pipelineColors[pipelineKey], color: '#fff' }}
                                >
                                  {pipelineKey}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              style={{
                                backgroundColor: assignmentStatusColors[assignment.assignmentStatus] || '#d4edda',
                                borderRadius: '12px',
                                padding: '0.5rem 1rem',
                                fontSize: '0.7rem',
                                border: 'none',
                                boxShadow: 'none',
                                margin: '0.25rem',
                                display: 'inline-block',
                                textAlign: 'center',
                                color: '#fff',
                              }}
                            >
                              {assignment.assignmentStatus}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                              {Object.keys(assignmentStatusColors).map((statusKey) => (
                                <Dropdown.Item
                                  key={statusKey}
                                  onClick={() => handleAssignmentStatusChange(assignment.id, statusKey)}
                                  style={{ backgroundColor: assignmentStatusColors[statusKey], color: '#fff' }}
                                >
                                  {statusKey}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">No assignments available for the selected date.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ScheduleDailyAssignmentAgent;