import React from 'react';
import { Table, Col, Button } from 'react-bootstrap';
import moment from 'moment';

const DateKey = () => {
  const currentDate = moment();
  const dateRanges = [
    { label: 'Yesterday', value: currentDate.clone().subtract(1, 'days').format('MM/DD'), color: '#0b53a9', textColor: 'white' },
    { label: '2 Days Ago', value: currentDate.clone().subtract(2, 'days').format('MM/DD'), color: '#5a3386', textColor: 'white' },
    { label: '3 Days Ago', value: currentDate.clone().subtract(3, 'days').format('MM/DD'), color: '#ffc8aa' },
    { label: '4-7 Days Old', value: `${currentDate.clone().subtract(7, 'days').format('MM/DD')}-${currentDate.clone().subtract(4, 'days').format('MM/DD')}`, color: '#ffe5a0' },
    { label: '8-31 Days Old', value: `${currentDate.clone().subtract(31, 'days').format('MM/DD')}-${currentDate.clone().subtract(8, 'days').format('MM/DD')}`, color: '#bee1f5' },
    { label: '32-60 Days Old', value: `${currentDate.clone().subtract(60, 'days').format('MM/DD')}-${currentDate.clone().subtract(32, 'days').format('MM/DD')}`, color: '#c6dae1' },
    { label: '61-90 Days Old', value: `${currentDate.clone().subtract(90, 'days').format('MM/DD')}-${currentDate.clone().subtract(61, 'days').format('MM/DD')}`, color: '#e8eaee' },
    { label: '91+ Days Old', value: `${currentDate.clone().subtract(91, 'days').format('MM/DD')} and earlier`, color: '#215a6d', textColor: 'white' },
  ];

  const darkenColor = (color, percent) => {
    let num = parseInt(color.replace("#",""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) - amt,
        G = (num >> 8 & 0x00FF) - amt,
        B = (num & 0x0000FF) - amt;
    return "#" + (
        0x1000000 +
        (R < 255 ? (R < 0 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 0 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 0 ? 0 : B) : 255)
    ).toString(16).slice(1);
  };

  return (
    <Col md={3} style={{ marginTop: '2rem', marginLeft: '4rem' }}>
      <Table
        bordered
        style={{
          borderRadius: '5px',
          overflow: 'hidden',
          backgroundColor: '#f8f9fa',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        <thead>
          <tr>
            <th colSpan="2" style={{ textAlign: 'left', backgroundColor: '#e6e7e8', color: '#525f7f', fontWeight: 'bold' }}>
              DATE KEY
            </th>
          </tr>
        </thead>
        <tbody>
          {dateRanges.map((range, index) => {
            const darkerColor = range.textColor === 'white' ? 'white' : darkenColor(range.color, 50); // Use white if specified
            return (
              <tr key={index}>
                <td>
                  <Button
                    variant="success"
                    style={{
                      backgroundColor: range.color,
                      color: darkerColor,
                      borderRadius: '12px',
                      padding: '0.5rem 1rem',
                      fontSize: '0.7rem',
                      border: 'none',
                      boxShadow: 'none',
                      margin: '0.25rem',
                      display: 'inline-block',
                      textAlign: 'center',
                    }}
                  >
                    {range.label}
                  </Button>
                </td>
                <td>{range.value}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
};

export default DateKey;
