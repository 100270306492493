import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import SideNav from "../SideNav/SideNav.js";

import "./navbar.css";

function Header({ getBrandText, onCampaignSelect, onFirmSelect, selectedAgentName, isAdmin, activeTab, onTabSelect }) {
  const location = useLocation();
  const [selectedCampaign, setSelectedCampaign] = useState("All Campaign");
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [selectedFirm, setSelectedFirm] = useState("All Firms");
  const [selectedFirmId, setSelectedFirmId] = useState(null);
  const [isOpenCampaign, setIsOpenCampaign] = useState(false);
  const [isOpenFirm, setIsOpenFirm] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [firms, setFirms] = useState([]);

  useEffect(() => {
    fetchCampaigns();
    fetchFirms();
  }, []);

  useEffect(() => {
    console.log("Received selectedAgentName prop:", selectedAgentName);
  }, [selectedAgentName]);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const { campaigns } = response.data;
      const sortedCampaigns = campaigns.sort((a, b) => a.name.localeCompare(b.name));
      const campaignsWithDefault = [{ id: null, name: "All Campaign" }, ...sortedCampaigns];
      setCampaigns(campaignsWithDefault);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const { firms } = response.data;
      const sortedFirms = firms.sort((a, b) => a.name.localeCompare(b.name));
      const firmsWithDefault = [{ id: null, name: "All Firms" }, ...sortedFirms];
      setFirms(firmsWithDefault);
    } catch (error) {
      console.error("Error fetching firms:", error);
    }
  };

  const handleCampaignSelect = (campaignId, campaignName) => {
    setSelectedCampaign(campaignName);
    setSelectedCampaignId(campaignId);
    setIsOpenCampaign(!isOpenCampaign);
    onCampaignSelect(campaignId);
  };

  const handleFirmSelect = (firmId, firmName) => {
    setSelectedFirm(firmName);
    setSelectedFirmId(firmId);
    setIsOpenFirm(!isOpenFirm);
    onFirmSelect(firmId);
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/main":
        return <span className="page-title">Intake Control Center</span>;
      case "/coverage":
        return (
          <span className="page-title">
            {selectedAgentName ? `${selectedAgentName}` : "Waterfall Coverage Schedule"}
          </span>
        );
      case "/database":
        return <span className="page-title">Agent Database</span>;
      case "/report":
        return <span className="page-title">Agent Report Form</span>;
      case "/schedule":
        return <span className="page-title">Agent Schedule</span>;
      case "/retainer":
        return <span className="page-title">Retainer Tracker Sheet</span>;
      default:
        return "";
    }
  };

  const getAdditionalText = () => {
    switch (location.pathname) {
      case "/main":
      case "/coverage":
      case "/database":
      case "/report":
      case "/retainer":
        return <span className="page-additional-text">Your current intake summary and activity.</span>;
      default:
        return "";
    }
  };

  const shouldHideDropdowns = () => {
    return ["/report", "/schedule", "/retainer", "/database"].includes(location.pathname);
  };

  const handleTabSelect = (tab) => {
    onTabSelect(tab);
  };

  return (
    <Navbar bg="white" expand="lg" className="sticky-top" style={{ paddingTop: '2rem', borderBottom: '1px solid #ccc' }} >
      <Container fluid>

        <Navbar.Brand href="#home">
          {getPageTitle()}
          <div>{getAdditionalText()}</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar >

          </Nav>
          <Nav className="ml-auto" navbar style={{ marginBottom: '2rem' }}>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon"></span>
              </Nav.Link>
            </Nav.Item>

            {!shouldHideDropdowns() && (
              <>
                <DropdownButton
                  as={Nav.Item}
                  id="campaignDropdown"
                  title={
                    <>
                      {selectedCampaign}{' '}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  variant="default"
                  className="m-0 custom-dropdown"
                  style={{ color: 'black' }}
                >
                  {campaigns.map((campaign) => (
                    <Dropdown.Item
                      key={campaign.id}
                      onClick={() => handleCampaignSelect(campaign.id, campaign.name)}
                    >
                      {campaign.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <DropdownButton
                  as={Nav.Item}
                  id="firmDropdown"
                  title={
                    <>
                      {selectedFirm}{' '}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </>
                  }
                  variant="default"
                  className="m-0 custom-dropdown"
                  style={{ color: 'black' }}
                >
                  <Dropdown.Menu
                    style={{
                      maxHeight: '500px',
                      overflowY: 'auto'
                    }}
                  >
                    {firms.map((firm) => (
                      <Dropdown.Item
                        key={firm.id}
                        onClick={() => handleFirmSelect(firm.id, firm.name)}
                      >
                        {firm.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </DropdownButton>
                
              </>
            )}
          </Nav>


        </Navbar.Collapse>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            {location.pathname === "/schedule" && (
              <Nav
                variant="underline"
                defaultActiveKey={activeTab}
                onSelect={handleTabSelect}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '4rem',
                }}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="AgentSchedule"
                    style={{
                      padding: '0.7rem 1rem',
                      textAlign: 'left',
                      borderBottom: activeTab === "AgentSchedule" ? '3px solid #6841c7' : 'none',
                      color: activeTab === "AgentSchedule" ? '#6841c7' : 'black'
                    }}
                  >
                    Agent Schedule
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="DailyAssignment"
                    style={{
                      padding: '0.7rem 1rem',
                      textAlign: 'left',
                      borderBottom: activeTab === "DailyAssignment" ? '3px solid #6841c7' : 'none',
                      color: activeTab === "DailyAssignment" ? '#6841c7' : 'black'
                    }}
                  >
                    Daily Assignment
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="DateKey"
                    style={{
                      padding: '0.7rem 1rem',
                      textAlign: 'left',
                      borderBottom: activeTab === "DateKey" ? '3px solid #6841c7' : 'none',
                      color: activeTab === "DateKey" ? '#6841c7' : 'black'
                    }}
                  >
                    Date Key
                  </Nav.Link>
                </Nav.Item>



                {isAdmin && (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="AssignmentTemplate"
                        style={{
                          padding: '0.7rem 1rem',
                          textAlign: 'left',
                          borderBottom: activeTab === "AssignmentTemplate" ? '3px solid #6841c7' : 'none',
                          color: activeTab === "AssignmentTemplate" ? '#6841c7' : 'black'
                        }}
                      >
                        Assignment Template
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        eventKey="ScheduleLog"
                        style={{
                          padding: '0.7rem 1rem',
                          textAlign: 'left',
                          borderBottom: activeTab === "ScheduleLog" ? '3px solid #6841c7' : 'none',
                          color: activeTab === "ScheduleLog" ? '#6841c7' : 'black'
                        }}
                      >
                        Schedule Log
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="ScheduleApproval"
                        style={{
                          padding: '0.7rem 1rem',
                          textAlign: 'left',
                          borderBottom: activeTab === "ScheduleApproval" ? '3px solid #6841c7' : 'none',
                          color: activeTab === "ScheduleApproval" ? '#6841c7' : 'black'
                        }}
                      >
                        Schedule Approval
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey="GoogleSheet"
                    style={{
                      padding: '0.7rem 1rem',
                      textAlign: 'left',
                      borderBottom: activeTab === "GoogleSheet" ? '3px solid #6841c7' : 'none',
                      color: activeTab === "GoogleSheet" ? '#6841c7' : 'black'
                    }}
                  >
                    Google Sheet
                  </Nav.Link>
                </Nav.Item>


              </Nav>
            )}
          </div>
      </Container>
    </Navbar>
  );
}

export default Header;

