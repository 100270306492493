import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import { FaCalendar } from 'react-icons/fa';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Form.Control
    type="text"
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly
    className="custom-input"
  />
));

function DateRange({ startDate, setStartDate, endDate, setEndDate }) {
  const handleStartDateChange = (date) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      setStartDate(newDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      setEndDate(newDate);
    }
  };

  return (
    <div style={{ marginTop: '2rem', marginBottom: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
      <div className="daterange-container">
        <Form.Group className="custom-datepicker-input" style={{ marginRight: '15px' }}>
          <Form.Label className="custom-datepicker-input" style={{ fontWeight: 'bold' }}>From:</Form.Label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={<CustomInput />}
            popperPlacement="bottom-end"
            calendarClassName="custom-datepicker"
          />
        </Form.Group>
        <Form.Group className="custom-datepicker-input" style={{ marginRight: '15px' }}>
          <Form.Label style={{ fontWeight: 'bold' }}>To:</Form.Label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="MM/dd/yyyy"
            customInput={<CustomInput />}
            popperPlacement="bottom-end"
            calendarClassName="custom-datepicker"
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default DateRange;
