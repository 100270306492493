import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar.js";
import CoverageWaterfallAgent from "../components/CoverageWaterfall/Agent/CoverageWaterfall.js";
import CoverageWaterfallDayAgent from "../components/CoverageWaterfall/Agent/CoverageWaterfallDay/CoverageWaterfallDay.js";
import CoverageWaterfallAdmin from "../components/CoverageWaterfall/Admin/CoverageWaterfall.js";
import CoverageWaterfallDayAdmin from "../components/CoverageWaterfall/Admin/CoverageWaterfallDay/CoverageWaterfallDay.js";
import AgentViewAgentsList from "../components/CoverageWaterfall/Agent/AgentsList.js";


function Coverage() {
  const [selectedOption, setSelectedOption] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [showWaterfall, setShowWaterfall] = useState(true);
  const [selectedAgentName, setSelectedAgentName] = useState("");
  const [showAgentView, setShowAgentView] = useState(true);

  useEffect(() => {
    const isAdminValue = localStorage.getItem("isAdmin");
    setIsAdmin(isAdminValue === "true");
  }, []);

  const handleCampaignSelect = (campaignId) => {
    setSelectedCampaign(campaignId);
  };

  const handleFirmSelect = (firmId) => {
    setSelectedFirm(firmId);
  };

  const handleAgentSelect = (agentName) => {
    setSelectedAgentName(agentName);
    setShowAgentView(false);
  };

  const handleAgentClick = (agent) => {
    setSelectedAgentName(agent); // remove by sir jum.
    setShowWaterfall(false);
  };

  const handleAllCampaignClick = () => {
    setShowWaterfall(true);
  };

  return (
    <>
      <Navbar
        onCampaignSelect={handleCampaignSelect}
        onFirmSelect={handleFirmSelect}
        selectedAgentName={selectedAgentName}
      />

      {isAdmin ? (
        <>
          {showWaterfall ? (
            <CoverageWaterfallAdmin 
              onAgentClick={handleAgentClick}
              selectedCampaignId={selectedCampaign}
              selectedFirmId={selectedFirm}
            />
          ) : (
            <CoverageWaterfallDayAdmin 
              onAllCampaignClick={handleAllCampaignClick} 
              onAgentClick={handleAgentClick}
              selectedCampaignId={selectedCampaign}
              selectedFirmId={selectedFirm}
            />
          )}
        </>
      ) : (
        <>
          {showWaterfall ? (
            <CoverageWaterfallAgent 
              onAgentClick={handleAgentClick}
              selectedCampaignId={selectedCampaign}
              selectedFirmId={selectedFirm}
            />
          ) : (
            <CoverageWaterfallDayAgent
              onAllCampaignClick={handleAllCampaignClick} 
              onAgentClick={handleAgentClick}
              selectedCampaignId={selectedCampaign}
              selectedFirmId={selectedFirm}
            />
          )}
        </>
      )}

      {/*{!isAdmin && showAgentView && (
        <AgentViewAgentsList onAgentSelect={handleAgentSelect} />
      )}*/}


    </>
  );
}

export default Coverage;