import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';
import checkbutton from '../../assets/icons/checkbutton.png';
import xbutton from '../../assets/icons/xbutton.png';
import Swal from 'sweetalert2';

const ScheduleApproval = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const pageSize = 25;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetChangeScheduleRequestsForApprovalList?campaignId=0&firmId=0');
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApproval = async (index) => {
    try {
      const updatedData = [...data];
      updatedData[index].isApproved = true;
      setData(updatedData);

      await axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/PostUpdateChangeScheduleRequest', {
        id: updatedData[index].Id,
        isApproved: true
      });

      await fetchData();
      Swal.fire({
        icon: 'success',
        title: 'Approved!',
        text: 'The request has been approved successfully.',
      });
      
    } catch (error) {
      console.error('Error approving:', error);
    }
  };

  const handleRejection = async (index) => {
    try {
      const updatedData = [...data];
      updatedData[index].isApproved = false;
      setData(updatedData);

      await axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/PostUpdateChangeScheduleRequest', {
        id: updatedData[index].Id,
        isApproved: false
      });

      await fetchData();
      Swal.fire({
        icon: 'success',
        title: 'Rejected!',
        text: 'The request has been rejected successfully.',
      });
      
    } catch (error) {
      console.error('Error rejecting:', error);
    }
  };

  const renderTableRows = (pageNumber) => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return data.slice(startIndex, endIndex).map((row, index) => (
      <tr key={index}>
        <td>{row.Id}</td>
        <td>{row.AgentName}</td>
        <td>{new Date(row.OriginalScheduleDate).toLocaleDateString()}</td>
        <td>{new Date(row.OriginalStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
        <td>{new Date(row.OriginalEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
        <td>{new Date(row.RequestedScheduleDate).toLocaleDateString()}</td>
        <td>{new Date(row.RequestedStartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
        <td>{new Date(row.RequestedEndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
        <td>
          <img
            src={checkbutton}
            alt="Approve"
            onClick={() => handleApproval(index)}
            style={{ cursor: 'pointer' }}
          />
          {' '}
          <img
            src={xbutton}
            alt="Reject"
            onClick={() => handleRejection(index)}
            style={{ cursor: 'pointer' }}
          />
        </td>
      </tr>
    ));
  };

  const totalPages = Math.ceil(data.length / pageSize);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <Card className="mb-4 bg-white" style={{ borderTop: '3px solid #7b50d5', color: 'black' }}>
        <Card.Header>
          <h5 className="card-title">Change Schedule Request (For Approval)</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Agent Name</th>
                <th>Original Scheduled Date</th>
                <th>From</th>
                <th>To</th>
                <th>Requested Date</th>
                <th>From</th>
                <th>To</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(currentPage)}</tbody>
          </Table>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Page <span id="currentPage">{currentPage}</span> of <span id="totalPages">{totalPages}</span>
            </div>
            <nav aria-label="Page navigation" className="d-flex justify-content-end">
              <ul className="pagination">
                <li className="page-item" onClick={handlePrevPage}>
                  <button className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo; Previous</span>
                  </button>
                </li>
                <li className="page-item" onClick={handleNextPage}>
                  <button className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">Next &raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ScheduleApproval;
