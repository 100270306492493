import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from 'axios';
import BackgroundImage from "../BackgroundImage/BackgroundImage.js";
import "./register.css";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setIsActive(
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      emailRegex.test(email) &&
      password !== "" &&
      confirmPassword !== "" &&
      password === confirmPassword &&
      document.getElementById("agreeCheckbox").checked
    );
  }, [firstName, lastName, email, password, confirmPassword]);

  const showErrorAlert = (text) => {
    Swal.fire({
      icon: "error",
      text: text,
    });
  };

  const registerUser = (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      showErrorAlert("Invalid email format. Please enter a valid email address.");
      return;
    }

    if (password !== confirmPassword) {
      showErrorAlert("Passwords do not match. Please enter matching passwords.");
      return;
    }

    if (!isValidPassword(password)) {
      showErrorAlert("Invalid password. Please enter a valid password.");
      return;
    }

    let data = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Username: username,
      Password: password 
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentAuth/AgentRegistration',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        if (response.data) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");

          Swal.fire({
            icon: "success",
            text: "Thank you for registering!",
          }).then(() => {
            navigate("/login");
          });
        } else {
          showErrorAlert("Error occurred. Please check your details and try again later.");
        }
      })
      .catch((error) => {
        console.error("Error registering user:", error);
        showErrorAlert("Error occurred. Please try again later.");
      });
  };

  const isValidPassword = (password) => {
    const minLength = 10;
    return password.length >= minLength;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <BackgroundImage />
      <Container style={{ position: 'relative', zIndex: 2001 }}>
        <Row className="justify-content-md-center mt-5">
          <Col md={6}>
            <Card className="mb-4 shadow-lg" style={{ backgroundColor: "white", borderColor: "none", marginTop: "10rem" }}>
              <Card.Body className="p-4">
                <Card
                  variant="gradient"
                  bg="primary"
                  border="light"
                  borderRadius="lg"
                  text="white"
                  p={3}
                  mb={4}
                  textAlign="center"
                >
                  <h4 className="font-weight-medium ml-2 mt-2">Join us today</h4>
                  <p className="button-text my-1 ml-2">Enter your email and password to register</p>
                </Card>

                <Form onSubmit={registerUser}>
                  <Form.Control
                    type="text"
                    className="mb-2"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <Form.Control
                    type="text"
                    className="mb-2"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <Form.Control
                    type="email"
                    className="mb-2"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <Form.Control
                    type="text"
                    className="mb-2"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <Form.Control
                    type="password"
                    className="mb-2"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <Form.Control
                    type="password"
                    className="mb-2"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ color: "inherit" }}
                  />
                  <div className="register-checkbox-link">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      onChange={() => {
                        setIsActive(!isActive);
                      }}
                    />
                    <label htmlFor="agreeCheckbox" className="button-text font-weight-bold ml-1">
                      I agree the <span>Terms and Conditions</span>
                    </label>
                  </div>

                  <Button
                    variant="primary"
                    type="submit"
                    fullWidth
                    mt={4}
                    mb={1}
                    disabled={!isActive}
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    onClick={registerUser}
                  >
                    Sign Up
                  </Button>

                  <p className="button-text text-center" style={{ color: 'inherit' }}>
                    Already have an account?{" "}
                    <Link to="/login" className="font-weight-medium">
                      Sign In
                    </Link>
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;
