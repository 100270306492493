import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { FaCalendar } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpZA } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './mainagenttable.css';
import MainAgentTablePagination from './MainAgentTablePagination.js';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="custom-input"
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
      style={{
        alignItems: 'center',
        border: '1px solid #a2a9b2',
        borderRadius: '4px',
        boxShadow: isHovered ? '0 0 10px 1px #df00ff' : 'none',
        cursor: 'pointer',
        display: 'flex',
        padding: '8px',
      }}
    >
      <FaCalendar className="calendar-icon" style={{ marginRight: '8px' }} />
      {value || 'Select Start Date'}
    </div>
  );
});

const MainAgentTableSort = () => {
  const [agentsData, setAgentsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [isSortingMode, setIsSortingMode] = useState(false);

  const excludedAgents = [
    'Agent Viribuz',
    'Agent Irish',
    'Fad Junaid',
    'Pikachu Admin',
    'Casper MacFadden',
    'Casper McFadden',
    'RogerAdmin RobielosAdmin',
    'string string',
    'Test Agent',
    'TestX AgentX',
    'TestY AgentY'
  ];

  useEffect(() => {
    fetchAgentsData();
  }, [startDate]);

  const fetchAgentsData = async () => {
    try {
      const formattedDate = formatDate(startDate);
      console.log('Requesting data for date:', formattedDate);
      console.log('Sending request to server with date:', formattedDate);
      const response = await axios.get(
        `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAgentsDatabase?date=${formattedDate}`
      );
      console.log('Server response:', response.data);

      const filteredData = response.data.data.filter(agent => {
        const agentName = `${agent.FirstName} ${agent.LastName}`;
        return !excludedAgents.includes(agentName);
      });

      setAgentsData(filteredData);
    } catch (error) {
      console.error('Error fetching agents data:', error);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedAgents = [...agentsData].sort((a, b) => {
      if (key === 'FirstName' || key === 'LastName') {
        const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
        const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
        if (direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else {
        if (direction === 'ascending') {
          return a[key] - b[key];
        } else {
          return b[key] - a[key];
        }
      }
    });

    setAgentsData(sortedAgents);
  };

  const handleSortClick = () => {
    window.location.reload();
  };

  return (
    <div className="container-fluid" style={{ marginBottom: '3rem', marginTop: '2rem' }}>
      <div id="agents-table-css" className="card" style={{ borderTop: '3px solid #7b50d5', color: 'black' }}>
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h3 className="m-0" style={{ color: 'black' }}>Agents</h3>
          </div>

          <FontAwesomeIcon
            icon={faArrowUpZA}
            style={{ marginLeft: '1rem', fontSize: '30px', cursor: 'pointer', color: '#6841c7' }}
            onClick={handleSortClick}
          />

          <div className="d-flex align-items-center ml-auto">
            <h3 className="m-0 mr-2">Date:</h3>
            <DatePicker
              customInput={<CustomInput />}
              dateFormat="MM/dd/yyyy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Select Start Date"
              selected={startDate}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th onClick={() => requestSort('FirstName')}>
                  First Name {sortConfig.key === 'FirstName' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('LastName')}>
                  Last Name {sortConfig.key === 'LastName' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalPhoneCallsMade')}>
                  # Phone Calls {sortConfig.key === 'TotalPhoneCallsMade' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalMessagesSent')}>
                  # Message Sent {sortConfig.key === 'TotalMessagesSent' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalEmailsSent')}>
                  # Emails Send {sortConfig.key === 'TotalEmailsSent' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalContactReachOut')}>
                  # Contact React Total {sortConfig.key === 'TotalContactReachOut' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalDisqualifiedLeads')}>
                  # DQs {sortConfig.key === 'TotalDisqualifiedLeads' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalAppointmentsSet')}>
                  # Appt Sent {sortConfig.key === 'TotalAppointmentsSet' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalRetainersAcquired')}>
                  # Retainers {sortConfig.key === 'TotalRetainersAcquired' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalDocumentsSent')}>
                  # Documents Sent {sortConfig.key === 'TotalDocumentsSent' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalShowedAppointments')}>
                  # Showed Appt {sortConfig.key === 'TotalShowedAppointments' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('RetainersSet')}>
                  # of Retainers Set for {sortConfig.key === 'RetainersSet' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
                <th onClick={() => requestSort('TotalLiveTransferSent')}>
                  # Live Transfers Sent {sortConfig.key === 'TotalLiveTransferSent' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
                </th>
              </tr>
            </thead>
            <tbody>
              {agentsData.map((agent, index) => (
                <tr key={index}>
                  <td>{agent.FirstName}</td>
                  <td>{agent.LastName}</td>
                  <td>{agent.TotalPhoneCallsMade}</td>
                  <td>{agent.TotalMessagesSent}</td>
                  <td>{agent.TotalEmailsSent}</td>
                  <td>{agent.TotalContactReachOut}</td>
                  <td>{agent.TotalDisqualifiedLeads}</td>
                  <td>{agent.TotalAppointmentsSet}</td>
                  <td>{agent.TotalRetainersAcquired}</td>
                  <td>{agent.TotalDocumentsSent}</td>
                  <td>{agent.TotalShowedAppointments}</td>
                  <td>{agent.RetainersSet}</td>
                  <td>{agent.TotalLiveTransferSent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MainAgentTableSort;
