import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import editIcon from '../../../assets/icons/edit.png';
import delIcon from '../../../assets/icons/delete.png';
import axios from 'axios';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import EditButton from './EditButton.js';
import '../retainertracker.css';

const AllCampaign = ({ retainers }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [lawFirmOptions, setLawFirmOptions] = useState([]);
  const medicalRecordOptions = ['YES', 'NO', 'N/A'];
  const retainerPushedOptions = ['YES', 'NO', 'N/A', 'PAUSED'];
  const [tableData, setTableData] = useState(retainers);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedLawFirm, setSelectedLawFirm] = useState(null);
  const pageSize = 5;
  const [searchQuery, setSearchQuery] = useState('');

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    fetchCampaigns();
    fetchFirms();
    setTableData(retainers);
  }, [retainers]);

  const fetchData = async (page) => {
    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/GetAllRetainers?page=${page}`);
      setTableData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const { campaigns } = response.data;
      const sortedCampaigns = campaigns.sort((a, b) => a.name.localeCompare(b.name));
      setCampaignOptions(sortedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetFirms');
      const { firms } = response.data;
      const sortedFirms = firms.sort((a, b) => a.name.localeCompare(b.name));
      setLawFirmOptions(sortedFirms);
    } catch (error) {
      console.error("Error fetching firms:", error);
    }
  };

  const [formData, setFormData] = useState({
    DateRetained: new Date(),
    CampaignId: '',
    LawFirmId: '',
    RetainerLT: '',
    FirstName: '',
    LastName: '',
    RetainerMethod: '',
    SystemUsed: '',
    MedicalRecord: '',
    RetainerPushed: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCampaignChange = (selectedOption) => {
    const campaign = campaignOptions.find(option => option.id === selectedOption);
    setSelectedCampaign(campaign);
  };

  const handleLawFirmChange = (selectedOption) => {
    const lawFirm = lawFirmOptions.find(option => option.id === selectedOption);
    setSelectedLawFirm(lawFirm);
  };

  const submitForm = () => {
    const id = localStorage.getItem('id');
    const updatedFormData = { ...formData, id };
    axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/PostAddNewRetainer', updatedFormData)
      .then(response => {
        console.log('Data sent to server:', updatedFormData);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Submission successful!',
        });
        setFormData({
          id: '',
          DateRetained: '',
          CampaignId: '',
          LawFirmId: '',
          RetainerLT: '',
          FirstName: '',
          LastName: '',
          RetainerMethod: '',
          SystemUsed: '',
          MedicalRecord: '',
          RetainerPushed: '',
        });
        closeModal();
      })
      .catch(error => {
        console.error('Error submitting data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.message || 'Something went wrong!',
        });
      });
  };

  const onDeleteClick = async (Id) => {
    try {
      const response = await axios.delete(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentRetainer/DeleteRetainer/${Id}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      setTableData(prevData => prevData.filter(retainer => retainer.Id !== Id));
    } catch (error) {
      console.error('Error deleting retainer:', error);
    }
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setFormData({
      DateRetained: new Date(),
      CampaignId: '',
      LawFirmId: '',
      RetainerLT: '',
      FirstName: '',
      LastName: '',
      RetainerMethod: '',
      SystemUsed: '',
      MedicalRecord: '',
      RetainerPushed: '',
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedTableData = React.useMemo(() => {
    let sortedData = [...tableData];
    if (sortConfig.key !== null) {
      sortedData.sort((a, b) => {
        let aKey = a[sortConfig.key];
        let bKey = b[sortConfig.key];

        if (sortConfig.key === 'DateRetained') {
          aKey = new Date(aKey);
          bKey = new Date(bKey);
        }

        if (aKey < bKey) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [tableData, sortConfig]);

  const renderTableRows = () => {
    const filteredTableData = sortedTableData.filter(retainer =>
      `${retainer.FirstName} ${retainer.LastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredTableData.map((retainer) => (
      <tr key={retainer.Id}>
        <td>{format(new Date(retainer.DateRetained), 'MM/dd/yyyy')}</td>
        <td>{retainer.CampaignId ? campaignOptions.find(campaign => campaign.id === retainer.CampaignId)?.name : ''}</td>
        <td>{retainer.LawFirmId ? lawFirmOptions.find(firm => firm.id === retainer.LawFirmId)?.name : ''}</td>
        <td>{retainer.RetainerLT}</td>
        <td>{retainer.FirstName} {retainer.LastName}</td>
        <td>{retainer.RetainerMethod}</td>
        <td>{retainer.SystemUsed}</td>
        <td>{retainer.MedicalRecord}</td>
        <td>{retainer.RetainerPushed}</td>
        <td style={{ visibility: 'hidden', width: '5px', padding: '0', margin: '0' }}>{retainer.Id}</td>
        <td>
          <EditButton
            rowData={retainer}
            fetchData={fetchData}
            currentPage={currentPage}
            medicalRecordOptions={medicalRecordOptions}
            retainerPushedOptions={retainerPushedOptions}
          />
          <img
            src={delIcon}
            alt="Delete"
            onClick={() => onDeleteClick(retainer.Id)}
            style={{
              cursor: 'pointer',
              marginRight: '5px',
              filter: 'brightness(1.2)',
              transition: 'filter 0.3s ease-in-out',
              borderRadius: '20%',
            }}
            className="retainer-icon-hover-glow"
          />
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <div className="card bg-white card-with-box-shadow">
        <div className="card-body">
          <h3 className="card-title d-flex justify-content-between align-items-center" style={{ color: 'black' }}>
            <span>All Campaign</span>
            <div className="d-flex align-items-center">
              <input
                type="text"
                placeholder="Search by Lead Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  marginRight: '1rem',
                  padding: '0.5rem',
                  fontSize: '1rem',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
              />
              <button
                className="btn"
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  fontSize: '14px',
                  padding: '5px 10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                onClick={openModal}
              >
                Add Lead +
              </button>
            </div>
          </h3>
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th onClick={() => handleSort('DateRetained')}>Date Retained</th>
                  <th onClick={() => handleSort('CampaignId')}>Campaign</th>
                  <th onClick={() => handleSort('LawFirmId')}>Law Firm</th>
                  <th onClick={() => handleSort('RetainerLT')}>Retainer LT</th>
                  <th onClick={() => handleSort('FirstName')}>Lead Name</th>
                  <th onClick={() => handleSort('RetainerMethod')}>Retainer Method</th>
                  <th onClick={() => handleSort('SystemUsed')}>System Used</th>
                  <th onClick={() => handleSort('MedicalRecord')}>Medical Record</th>
                  <th onClick={() => handleSort('RetainerPushed')}>Retainer Pushed</th>
                  <th style={{ visibility: 'hidden', width: '5px', padding: '0', margin: '0' }}>ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows()}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={modalVisible} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDateRetained">
              <Form.Label>Date Retained</Form.Label>
              <DatePicker
                selected={formData.DateRetained}
                onChange={(date) => setFormData((prevData) => ({ ...prevData, DateRetained: date }))}
                className="form-control"
              />
            </Form.Group>
            <Form.Group controlId="formCampaignId">
              <Form.Label>Campaign</Form.Label>
              <Form.Control
                as="select"
                name="CampaignId"
                value={formData.CampaignId}
                onChange={handleChange}
              >
                <option value="">Select a Campaign</option>
                {campaignOptions.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formLawFirmId">
              <Form.Label>Law Firm</Form.Label>
              <Form.Control
                as="select"
                name="LawFirmId"
                value={formData.LawFirmId}
                onChange={handleChange}
              >
                <option value="">Select a Law Firm</option>
                {lawFirmOptions.map((firm) => (
                  <option key={firm.id} value={firm.id}>
                    {firm.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formRetainerLT">
              <Form.Label>Retainer LT</Form.Label>
              <Form.Control
                type="text"
                name="RetainerLT"
                value={formData.RetainerLT}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="LastName"
                value={formData.LastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formRetainerMethod">
              <Form.Label>Retainer Method</Form.Label>
              <Form.Control
                type="text"
                name="RetainerMethod"
                value={formData.RetainerMethod}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSystemUsed">
              <Form.Label>System Used:</Form.Label>
              <Form.Control
                as="select"
                name="SystemUsed"
                value={formData.SystemUsed}
                onChange={handleChange}
              >
                <option value="">Select Option</option>
                <option value="GHL">GHL</option>
                <option value="Open Phone">Open Phone</option>
                <option value="Dialer">Dialer</option>
                <option value="Personal Phone">Personal Phone</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formMedicalRecord">
              <Form.Label>Medical Record:</Form.Label>
              <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, MedicalRecord: selectedOption }))}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                  {formData.MedicalRecord || 'Select Option'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {medicalRecordOptions.map((option) => (
                    <Dropdown.Item key={option} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Group controlId="formRetainerPushed">
              <Form.Label>Retainer Pushed:</Form.Label>
              <Dropdown onSelect={(selectedOption) => setFormData((prevData) => ({ ...prevData, RetainerPushed: selectedOption }))}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: '100%', border: '1px solid #cbcbcb' }}>
                  {formData.RetainerPushed || 'Select Option'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {retainerPushedOptions.map((option) => (
                    <Dropdown.Item key={option} eventKey={option}>
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '10px', marginRight: '20px' }}>
          <Button variant="secondary" onClick={closeModal} style={{ border: '1px solid #bfc5cc' }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitForm} style={{ backgroundColor: '#7F56D9', borderColor: '#7F56D9', color: 'white' }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllCampaign;
