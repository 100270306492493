import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Row, Col, Dropdown } from 'react-bootstrap';


const DatabaseNavigation = ({ campaigns, onAgentChange, onCampaignChange, onTimeFrameChange }) => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState('0');
  const [selectedCampaign, setSelectedCampaign] = useState('');

  useEffect(() => {
    const fetchAgents = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('ViribuzAccessToken');
        if (!token) {
          console.error('No access token found in localStorage');
          setError('No access token found');
          setLoading(false);
          return;
        }

        const excludedEmails = [
          'admin@mail.com',
          'casper@mail.com',
          'agent@mail.com',
          'testagentx@agent.com',
          'testagenty@agent.com',
          'string',
          'rogeradmin@agent.com'
        ];

        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
          headers: {
            'ViribuzAccessToken': token,
          }
        });

        const filteredAgents = response.data.data
          .filter(agent => !agent.isAdmin)
          .filter(agent => !excludedEmails.includes(agent.email))
          .map(agent => ({
            id: agent.id,
            name: `${agent.firstName} ${agent.lastName}`
          }));

        setAgents(filteredAgents);
      } catch (error) {
        console.error('Error fetching agents:', error);
        setError('Error fetching agents');
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleAgentChange = (agentId) => {
    setSelectedAgent(agentId);
    onAgentChange(agentId);
  };

  const handleCampaignChange = (campaignId) => {
    setSelectedCampaign(campaignId);
    onCampaignChange(campaignId);
  };

  return (
    <Row>
      <Col className="d-flex justify-content-end" style={{ marginTop: '1rem' }}>
        <div className="d-flex align-items-center">
          {loading && <p>Loading agents...</p>}
          {error && <p>{error}</p>}

          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-agents" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px', marginRight: '10px' }}>
              {selectedAgent === '0' ? 'All Agents' : agents.find(agent => agent.id.toString() === selectedAgent)?.name || 'Unnamed Agent'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <Dropdown.Item onClick={() => handleAgentChange('0')}>All Agents</Dropdown.Item>
              {agents.map(agent => (
                <Dropdown.Item key={agent.id} onClick={() => handleAgentChange(agent.id.toString())}>
                  {agent.name || 'Unnamed Agent'}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-campaigns" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px', marginRight: '10px' }}>
              {campaigns.find(campaign => campaign.id.toString() === selectedCampaign)?.name || 'Select Campaign'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {campaigns.map(campaign => (
                <Dropdown.Item key={campaign.id} onClick={() => handleCampaignChange(campaign.id.toString())}>
                  {campaign.name || 'Unnamed Campaign'}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className="datepicker-wrapper">
            <button onClick={() => onTimeFrameChange('months')} className="btn btn-info" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px', marginRight: '10px' }}>Months</button>
            <button onClick={() => onTimeFrameChange('weeks')} className="btn btn-info" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px', marginRight: '10px' }}>Weeks</button>
            <button onClick={() => onTimeFrameChange('days')} className="btn btn-info" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px', marginRight: '10px' }}>Days</button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

DatabaseNavigation.propTypes = {
  campaigns: PropTypes.array.isRequired,
  onAgentChange: PropTypes.func.isRequired,
  onCampaignChange: PropTypes.func.isRequired,
  onTimeFrameChange: PropTypes.func.isRequired,
};

export default DatabaseNavigation;
