import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import moment from "moment";

moment.locale("en-US");

const AgentsList = ({ onAgentClick, currentWeek }) => {
  const [agents, setAgents] = useState([]);
  const [showAllAgents, setShowAllAgents] = useState(false);

  useEffect(() => {
    fetchAgents();
  }, [currentWeek]);

  useEffect(() => {
    fetchAgents();
  }, [showAllAgents]);

  const fetchAgents = async () => {
    try {
      const accessToken = localStorage.getItem('ViribuzAccessToken');
      

      if (accessToken) {
        const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetAgentsHoursByWeek?startDate=${moment(currentWeek.startDate).format('YYYY-MM-DD')}&endDate=${moment(currentWeek.endDate).format('YYYY-MM-DD')}`, {
          headers: {
            'ViribuzAccessToken': accessToken,
            
          },
        });

        const emailsToExclude = [
          'admin@mail.com',
          'casper@mail.com',
          'agent@mail.com',
          'string',
          'testagentx@agent.com',
          'testagenty@agent.com',
        ];

        console.log('fetchAgents > response: ', response.data.data);

        let filteredAgents = response.data.data.filter(agent => 
          !agent.isAdmin && 
          !emailsToExclude.includes(agent.email)
        );

        if (!showAllAgents) {
          filteredAgents = filteredAgents.filter(agent => agent.totalHours !== 0.0 && agent.email !== 'roger@agent.com');
          filteredAgents = filteredAgents.slice(0, 6);
        } else {
          filteredAgents = filteredAgents.filter(agent => agent.email !== 'roger@agent.com');
          filteredAgents.sort((a, b) => b.totalHours - a.totalHours);
        }

        const modifiedAgents = filteredAgents.map(agent => ({
          ...agent,
          avatarUrl: 'https://w7.pngwing.com/pngs/893/926/png-transparent-silhouette-user-icon-profile-silhouette-silhouette-avatar-profile-silhouette-thumbnail.png',
        }));

        const sortedAgents = modifiedAgents.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setAgents(sortedAgents);
      } else {
        console.error('Access token not found in localStorage.');
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const handleAgentClick = (agent) => {
    onAgentClick(`${agent.firstName} ${agent.lastName}`);
    console.log('Clicked on agent:', agent.id);
  };

  const toggleShowAllAgents = () => {
    setShowAllAgents(prevShowAllAgents => !prevShowAllAgents);
  };

  return (
    <Card className="agents-list-container" style={{ width: '90%', height: '46rem', overflowY: 'auto', margin: '20px auto', marginLeft: '10px' }}>
      <Card.Body>
        <Card.Title style={{ fontWeight: 'bold', fontSize: '25px' }}>Agents</Card.Title>
        <Card.Text style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }}>Schedule for this week</Card.Text>
        <div className="agents-list">
          <div className="agents-list-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <p>Employee</p>
            <p>Hours</p>
          </div>
          {agents.map(agent => (
            <Card key={agent.id} className="agent-item" style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleAgentClick(agent)}>
              <Card.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="user-info" style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={agent.avatarUrl} alt="User Avatar" style={{ height: '35px', width: '34px', borderRadius: '50%', border: '3px solid white' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <p style={{ margin: '0', marginBottom: '5px' }}>{agent.firstName} {agent.lastName}</p>
                    <p style={{ margin: '0' }}>{agent.email}</p>
                  </div>
                </div>
                  <div style={{ maginRight: '2px' }}>
                    <p style={{ margin: '0', marginBottom: '5px' }}>hours</p>
                    <p style={{ margin: '0' }}>{agent.totalHours}</p>
                  </div>
              </Card.Body>
            </Card>
          ))}
        </div>
        <p style={{ textAlign: 'right', cursor: 'pointer', color: '#6841c7', fontWeight: 'bold' }} onClick={toggleShowAllAgents}>
          {showAllAgents ? 'Show Less' : 'View All'}
        </p>
      </Card.Body>
    </Card>
  );
};

export default AgentsList;