import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { FaCalendar } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpZA } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import './mainagenttable.css';
import MainAgentTableSort from './MainAgentTableSort.js';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <FaCalendar className="calendar-icon" />
    {value}
  </div>
));

const MainAgentTableDropdown = ({ onAgentFilterChange, selectedAgent }) => {
  const [agentsData, setAgentsData] = useState([]);
  const [datePickerDate, setDatePickerDate] = useState(new Date());
  const [filteredAgentsData, setFilteredAgentsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [isSortingMode, setIsSortingMode] = useState(false);

  useEffect(() => {
    filterAgentsData();
  }, [selectedAgent, agentsData]);

  useEffect(() => {
    fetchDataOnLoad();
  }, [startDate]);

  const fetchDataOnLoad = async () => {
    await fetchAgentsData();
  };

  const fetchAgentsData = async () => {
    try {
      const formattedDate = formatDate(startDate);
      console.log('Requesting data for date:', formattedDate);
      console.log('Sending request to server with date:', formattedDate);

      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAgentsDatabase?date=${formattedDate}`);
      
      console.log('Server response:', response.data);
      console.log('Server response status code:', response.status);

      const sortedData = response.data.data.sort((a, b) => {
        const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
        const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setAgentsData(sortedData);
    } catch (error) {
      console.error('Error fetching agents data:', error);
    }
  };

  const filterAgentsData = () => {
    const namesToExclude = [
      'Agent Viribuz',
      'Agent Irish',
      'Fad Junaid',
      'Pikachu Admin',
      'Casper MacFadden',
      'Casper McFadden',
      'RogerAdmin RobielosAdmin',
      'string string',
      'Test Agent',
      'TestX AgentX',
      'TestY AgentY',
    ];

    let filteredData = agentsData.filter(agent => 
      !namesToExclude.includes(`${agent.FirstName} ${agent.LastName}`)
    );

    if (selectedAgent) {
      filteredData = filteredData.filter(agent =>
        `${agent.FirstName} ${agent.LastName}` === selectedAgent
      );
    }
    setFilteredAgentsData(filteredData);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleAgentFilterChange = (event) => {
    onAgentFilterChange(event.target.value);
  };

  const handleSortClick = () => {
    setIsSortingMode(!isSortingMode);
  };

  return (
    <div
      className="container-fluid"
      style={{ marginBottom: '3rem', marginTop: '2rem', paddingLeft: '5rem' }}
    >
      {isSortingMode ? (
        <MainAgentTableSort />
      ) : (
      <div id="agents-table-css" className="card" style={{ borderTop: '3px solid #7b50d5', color: 'black' }}>
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h3 className="m-0" style={{ color: 'black' }}>Agents</h3>
            <select
              className="form-control"
              onChange={handleAgentFilterChange}
              style={{ marginLeft: '1rem', width: '200px' }}
              value={selectedAgent}
            >
              <option value="">

                All Agents
              </option>

              {agentsData.filter(agent => 
                ![
                  'Pikachu Admin',
                  'Casper MacFadden',
                  'Casper McFadden',
                  'RogerAdmin RobielosAdmin',
                  'string string',
                  'Test Agent',
                  'TestX AgentX',
                  'TestY AgentY'
                ].includes(`${agent.FirstName} ${agent.LastName}`)
              ).map(agent => (
                <option key={`${agent.FirstName}-${agent.LastName}`} value={`${agent.FirstName} ${agent.LastName}`}>
                  {`${agent.FirstName} ${agent.LastName}`}
                </option>
              ))}
            </select>
              <FontAwesomeIcon
                icon={faArrowUpZA}
                style={{ marginLeft: '1rem', fontSize: '30px', cursor: 'pointer', color: '#a2a9b2' }}
                onClick={handleSortClick}
              />
          </div>
          <div className="d-flex align-items-center">
            <h3 className="m-0 mr-2">Date:</h3>
            <DatePicker
              customInput={<CustomInput />}
              dateFormat="MM/dd/yyyy"
              onChange={(date) => {
                setStartDate(date);
                setDatePickerDate(date);
              }}
              placeholderText="Select Start Date"
              selected={datePickerDate}
              popperPlacement="bottom-end"
            />
          </div>
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th># Phone Calls</th>
                <th># Message Sent</th>
                <th># Emails Send</th>
                <th># Contact React Total</th>
                <th># DQs</th>
                <th># Appt Sent</th>
                <th># Retainers</th>
                <th># Documents Sent</th>
                <th># Showed Appt</th>
                <th># of Retainers Set for</th>
                <th># Live Transfers Sent</th>
              </tr>
            </thead>
            <tbody id="tableBody">
              {filteredAgentsData.map((agent, index) => (
                <tr key={index}>
                  <td>{agent.FirstName}</td>
                  <td>{agent.LastName}</td>
                  <td>{agent.TotalPhoneCallsMade}</td>
                  <td>{agent.TotalMessagesSent}</td>
                  <td>{agent.TotalEmailsSent}</td>
                  <td>{agent.TotalContactReachOut}</td>
                  <td>{agent.TotalDisqualifiedLeads}</td>
                  <td>{agent.TotalAppointmentsSet}</td>
                  <td>{agent.TotalRetainersAcquired}</td>
                  <td>{agent.TotalDocumentsSent}</td>
                  <td>{agent.TotalShowedAppointments}</td>
                  <td>{agent.RetainersSet}</td>
                  <td>{agent.TotalLiveTransferSent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      )}
    </div>
  );
};

export default MainAgentTableDropdown;
