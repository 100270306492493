import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Table, Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaRegCircle, FaRegDotCircle } from 'react-icons/fa';
import { CiCalendar } from "react-icons/ci";
import { format } from 'date-fns';
import Select from 'react-select';
import DeleteEvent from "../../../assets/icons/delete-event.png";
import Swal from 'sweetalert2';

const ScheduleAgentScheduleAdmin = () => {
  const tableStyle = {
    tableLayout: 'fixed',
    width: '100%',
  };

  const columnStyle = {
    width: '14.2857%',
  };

  const iconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    cursor: 'pointer',
  };

  const [shiftOptions] = useState([
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
  ]);

  const pmShiftOptions = shiftOptions.filter(option => {
    const [time, modifier] = option.split(' ');
    let [hours] = time.split(':');
    if (modifier === 'PM') {
      hours = parseInt(hours, 10);
      if (hours === 12) hours = 0;
      const totalMinutes = hours * 60 + 12 * 60;
      return totalMinutes >= 13 * 60 && totalMinutes <= 23 * 60;
    }
    return false;
  });


  const handleShiftStartChange = (shiftType, id, value) => {
    const updateShift = shiftType === 'am' ? [...amShiftAgents] : [...pmShiftAgents];
    const index = updateShift.findIndex(agent => agent.Id === id);
    if (index !== -1) {
      updateShift[index].StartTime = value;
      shiftType === 'am' ? setAmShiftAgents(updateShift) : setPmShiftAgents(updateShift);
    }
  };

  const handleShiftEndChange = (shiftType, id, value) => {
    const updateShift = shiftType === 'am' ? [...amShiftAgents] : [...pmShiftAgents];
    const index = updateShift.findIndex(agent => agent.Id === id);
    if (index !== -1) {
      updateShift[index].EndTime = value;
      shiftType === 'am' ? setAmShiftAgents(updateShift) : setPmShiftAgents(updateShift);
    }
  };

  const [agents, setAgents] = useState([]);
  const [status, setStatus] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [checkboxStates, setCheckboxStates] = useState({});
  const [showAddAgentModal, setShowAddAgentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [amShiftAgents, setAmShiftAgents] = useState([{shiftStart: '08:00 AM', shiftEnd: '04:00 PM', assignmentStatus: [],}]);
  const [pmShiftAgents, setPmShiftAgents] = useState([{shiftStart: '01:00 PM', shiftEnd: '11:00 PM', assignmentStatus: [],}]);    
  const [campaigns, setCampaigns] = useState([]);

  const filterSchedulesByDate = (schedules, selectedDate) => {
    return schedules.filter((schedule) => schedule.ScheduleDate === selectedDate);
  };


  const handleSaveChanges = () => {
    const formatTime = (time) => {
      if (!time) {
        return '12:00 AM';
      }
      const [timePart, modifier] = time.split(' ');
      let [hours, minutes] = timePart.split(':');
      
      hours = String(hours).padStart(2, '0');
      
      return `${hours}:${minutes} ${modifier}`;
    };

    const validateAgentData = (agent) => {
      if (!agent.AgentId) {
        Swal.fire('Error', 'Please select an agent.', 'error');
        return false;
      }
      if (!agent.StartTime) {
        Swal.fire('Error', 'Please select the shift start time.', 'error');
        return false;
      }
      if (!agent.EndTime) {
        Swal.fire('Error', 'Please select the shift end time.', 'error');
        return false;
      }
      return true;
    };

    const processAgentData = (shiftAgents, shiftType) =>
      shiftAgents.filter(validateAgentData).map(agent => ({
        Id: agent.Id || null,
        NumberOfAgents: agent.numberOfAgents || 1,
        ScheduleDate: selectedDate.toISOString().split('T')[0],
        ShiftStart: formatTime(agent.StartTime),
        ShiftEnd: formatTime(agent.EndTime),
        AgentId: agent.AgentId || null,
        AssignmentStatus: agent.AssignmentStatus || '2190',
        ShiftType: shiftType
      }));

    const newAgentsData = [
      ...processAgentData(amShiftAgents, 'A.M. Shift').filter(agent => !agent.Id),
      ...processAgentData(pmShiftAgents, 'P.M. Shift').filter(agent => !agent.Id)
    ];

    const existingAgentsData = [
      ...processAgentData(amShiftAgents, 'A.M. Shift').filter(agent => agent.Id),
      ...processAgentData(pmShiftAgents, 'P.M. Shift').filter(agent => agent.Id)
    ];

    const putRequest = existingAgentsData.length > 0 
      ? axios.request({
          method: 'put',
          maxBodyLength: Infinity,
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentScheduleV2/PutUpdateSchedules',
          headers: { 
            'Content-Type': 'application/json', 
            'Accept': 'application/json'
          },
          data: JSON.stringify(existingAgentsData)
        })
        .then(response => {
          if (response.data.success) {
            Swal.fire('Success', 'Existing schedules updated successfully!', 'success');
          } else {
            Swal.fire('Error', 'Failed to update schedules.', 'error');
          }
        })
        .catch(error => {
          console.error('Error updating schedules:', error);
          Swal.fire('Error', 'Error updating schedules.', 'error');
        })
      : Promise.resolve();

    const postRequest = newAgentsData.length > 0 
      ? axios.request({
          method: 'post',
          maxBodyLength: Infinity,
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentScheduleV2/PostAddSchedules',
          headers: { 
            'Content-Type': 'application/json', 
            'Accept': 'application/json'
          },
          data: JSON.stringify(newAgentsData)
        })
        .then(response => {
          if (response.data.success) {
            Swal.fire('Success', 'New schedules added successfully!', 'success');
          } else {
            Swal.fire('Error', 'Failed to add schedules.', 'error');
          }
        })
        .catch(error => {
          console.error('Error adding schedules:', error);
          Swal.fire('Error', 'Error adding schedules.', 'error');
        })
      : Promise.resolve();

    Promise.all([putRequest, postRequest])
      .then(() => {
        setTimeout(() => {
          handleDateChange(selectedDate);
        }, 6000);
      })
      .catch((error) => {
        console.error('Error processing schedule changes:', error);
      });
  };


  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
        const options = response.data.campaigns.map(campaign => ({
          value: campaign.id,
          label: campaign.name,
        }));
        setCampaigns(options);
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const accessToken = localStorage.getItem('ViribuzAccessToken');

        if (!accessToken) {
          console.error('Access token not found in localStorage');
          return;
        }

        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents',
          headers: { 
            'ViribuzAccessToken': accessToken,
          }
        };

        const response = await axios.request(config);
        const agentOptions = response.data.data.map(agent => ({
          value: agent.id,
          label: `${agent.firstName} ${agent.lastName}`
        }));

        setAgents(agentOptions);
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, []);

  const handleAgentChange = (shiftType, id, selectedOption) => {
    const updateShift = shiftType === 'am' ? [...amShiftAgents] : [...pmShiftAgents];
    const index = updateShift.findIndex(agent => agent.Id === id);
    if (index !== -1) {
      updateShift[index].AgentId = selectedOption ? selectedOption.value : null;
      if (shiftType === 'am') {
        setAmShiftAgents(updateShift);
      } else {
        setPmShiftAgents(updateShift);
      }
    }
  };

  const handleStatusChange = (shiftType, id, selectedOptions) => {
    const updateShift = shiftType === 'am' ? [...amShiftAgents] : [...pmShiftAgents];
    const index = updateShift.findIndex(agent => agent.Id === id);
    if (index !== -1) {
      updateShift[index].AssignmentStatus = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
      if (shiftType === 'am') {
        setAmShiftAgents(updateShift);
      } else {
        setPmShiftAgents(updateShift);
      }
    }
  };

  const addShiftRow = (shift) => {
      const newAgent = {
          id: Date.now(),  
          numberOfAgents: 1,
          shiftStart: shift === 'A.M.' ? '08:00 AM' : '01:00 PM',
          shiftEnd: shift === 'A.M.' ? '04:00 PM' : '09:00 PM',
          agentName: '',
          assignmentStatus: []  
      };

      if (shift === 'A.M.') {
          setAmShiftAgents([...amShiftAgents, newAgent]);
      } else {
          setPmShiftAgents([...pmShiftAgents, newAgent]);
      }

      setShowAddAgentModal(false);
  };

  const deleteSchedule = () => {
    const selectedIds = Object.keys(checkboxStates)
      .filter(key => checkboxStates[key])
      .map(key => {
        const id = key.replace('action', '');
        return { Id: parseInt(id, 10) };
      });

    if (selectedIds.length === 0) {
      Swal.fire('Warning', 'No schedules selected for deletion.', 'warning');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = JSON.stringify(selectedIds);

        const config = {
          method: 'delete',
          maxBodyLength: Infinity,
          url: 'http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentScheduleV2/DeleteSchedules',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then(response => {
            if (response.data.success) {
              Swal.fire('Deleted!', 'Schedule(s) deleted successfully!', 'success');
              setCheckboxStates({});
              handleDateChange(selectedDate);
            } else {
              Swal.fire('Error', 'Failed to delete schedule(s).', 'error');
            }
          })
          .catch(error => {
            console.error('Error deleting schedule(s):', error);
            Swal.fire('Error', 'Error deleting schedule(s).', 'error');
          });

        setShowDeleteModal(false);
      }
    });
  };

  const toggleCheckbox = (action) => {
    setCheckboxStates(prevState => ({
      ...prevState,
      [action]: !prevState[action],
    }));
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);
    const timezoneOffset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - timezoneOffset * 60000);
    const formattedDate = localDate.toISOString().split('T')[0];

    try {
      const response = await axios.get(
        `http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentScheduleV2/GetSchedulesByDateList?date=${formattedDate}`,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      );

      const { data } = response.data;
      const formattedData = data.map((agent) => ({
        ...agent,
        StartTime: formatTime(agent.StartTime),
        EndTime: formatTime(agent.EndTime),
      }));

      setSchedules(formattedData);

      const amShiftAgents = formattedData.filter((agent) => agent.ShiftType === 'A.M. Shift');
      const pmShiftAgents = formattedData.filter((agent) => agent.ShiftType === 'P.M. Shift');

      setAmShiftAgents(amShiftAgents.length > 0 ? amShiftAgents : []);
      setPmShiftAgents(pmShiftAgents.length > 0 ? pmShiftAgents : []);

    } catch (error) {
      console.error('Error fetching schedule data:', error);
    }
  };

  const formatTime = (time) => {
    if (!time) return '';

    const [hourMinute, period] = time.split(' ');
    let [hour, minute] = hourMinute.split(':');

    hour = hour.padStart(2, '0');

    return `${hour}:${minute} ${period}`;
  };

  const handleShiftTimeChange = (type, agentId, time, isStart) => {
    if (type === 'am') {
      const updatedAgents = amShiftAgents.map((agent) =>
        agent.Id === agentId
          ? {
              ...agent,
              StartTime: isStart ? time : agent.StartTime,
              EndTime: !isStart ? time : agent.EndTime,
            }
          : agent
      );
      setAmShiftAgents(updatedAgents);
    } else if (type === 'pm') {
      const updatedAgents = pmShiftAgents.map((agent) =>
        agent.Id === agentId
          ? {
              ...agent,
              StartTime: isStart ? time : agent.StartTime,
              EndTime: !isStart ? time : agent.EndTime,
            }
          : agent
      );
      setPmShiftAgents(updatedAgents);
    }
  };

  useEffect(() => {
    handleDateChange(selectedDate);
  }, [selectedDate]);

  const handleCancel = () => {
    const updatedAmShiftAgents = amShiftAgents.filter(agent => agent.Id);
    const updatedPmShiftAgents = pmShiftAgents.filter(agent => agent.Id);
    
    setAmShiftAgents(updatedAmShiftAgents);
    setPmShiftAgents(updatedPmShiftAgents);
  };

  const dayOfWeek = format(selectedDate, 'EEEE');

  return (
    <Container fluid style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <style>
        {`
          .custom-datepicker {
            border: none !important;
            box-shadow: none !important;
            text-align: center;
          }
          .datepicker-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .datepicker-container .react-datepicker-wrapper {
            width: 140px; 
          }
        `}
      </style>
      <Row className="justify-content-center">
        <div className="datepicker-container" style={{ border: '1px solid #cbcbcb', borderRadius: '10px', marginLeft: '1rem', padding: '5px', fontWeight: 'bold' }}>
          <CiCalendar style={{ fontSize: '1.5rem', marginRight: '-1rem' }} />
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            className="form-control custom-datepicker"
            popperPlacement="bottom-start"
            style={{ margin: '1px' }}
          />
          <span style={{ marginLeft: '-1rem' }}>- {dayOfWeek}</span>
        </div>
        <Col md={12}>
          <Card style={{ marginTop: '2rem', borderTop: '3px solid #7b50d5', color: 'black' }}>
            <Card.Body style={{ paddingLeft: '0', paddingRight: '0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h3 style={{ fontWeight: 'bold', marginLeft: '1rem' }}>Agent Schedule</h3>
                </div>
                <div style={{ marginRight: '1rem' }}>
                  <Button 
                    variant="danger" 
                    style={{ 
                      marginRight: '1rem', 
                      backgroundColor: 'white', 
                      border: '1px solid #cbcbcb', 
                      borderRadius: '10px', 
                      margin: '2px' 
                    }} 
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Schedule
                  </Button>
                  <Button variant="primary" style={{ backgroundColor: '#6841c7', borderRadius: '10px', margin: '2px' }} onClick={() => setShowAddAgentModal(true)}>+ Add Agent</Button>
                </div>
              </div>

              <div style={{ marginTop: '2rem', marginBottom: '0px', paddingBottom: '0px' }}>
                <Table striped bordered hover responsive style={{ marginBottom: '1rem' }}>
                  <thead>
                    <tr style={{ backgroundColor: 'white' }}>
                      <th style={columnStyle}>ID</th>
                      <th style={columnStyle}>Number of Agents</th>
                      <th style={columnStyle}>Shift Starts</th>
                      <th style={columnStyle}>Shift Ends</th>
                      <th style={columnStyle}>Agent Name</th>
                      <th style={columnStyle}>Assignment Status</th>
                      <th style={columnStyle}>Select Assignment</th>
                    </tr>
                  </thead>

                  <thead>
                    <tr>
                      <th style={columnStyle}>A.M. Shift</th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {amShiftAgents.map((agent, index) => (
                      <tr key={agent.Id} style={{ backgroundColor: 'white' }}>
                        <td style={columnStyle}>{agent.Id}</td>
                        <td style={columnStyle}>{index + 1}</td>
                        <td style={columnStyle}>
                          <Form.Control 
                            as="select" 
                            value={agent.StartTime || ""}
                            onChange={(e) => handleShiftStartChange('am', agent.Id, e.target.value)}
                          >
                            <option value="" disabled>
                              Select Shift Start
                            </option>
                            {shiftOptions.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td style={columnStyle}>
                          <Form.Control 
                            as="select" 
                            value={agent.EndTime || ""}
                            onChange={(e) => handleShiftEndChange('am', agent.Id, e.target.value)}
                          >
                            <option value="" disabled>
                              Select Shift End
                            </option>
                            {shiftOptions.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td style={columnStyle}>
                          <Select
                            options={agents}
                            placeholder="Select Agent"
                            value={agents.find(agentOption => agentOption.value === agent.AgentId)}
                            onChange={(selectedOption) => handleAgentChange('am', agent.Id, selectedOption)}
                          />
                        </td>
                        <td style={columnStyle}>
                          <Select
                            isMulti
                            options={campaigns}
                            placeholder="Select Status"
                            value={campaigns.filter(campaignOption => (agent.AssignmentStatus || []).includes(campaignOption.value))}
                            onChange={(selectedOptions) => handleStatusChange('am', agent.Id, selectedOptions)}
                          />                     
                        </td>
                        <td style={columnStyle}>
                          <div style={iconStyle} onClick={() => toggleCheckbox(`action${agent.Id}`)}>
                            {checkboxStates[`action${agent.Id}`] ? <FaRegDotCircle style={{ color: '#de01ff' }} /> : <FaRegCircle style={{ color: '#a2a9b2' }} />}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div style={{ marginTop: '-1rem' }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th style={columnStyle}>P.M. Shift</th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                      <th style={columnStyle}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pmShiftAgents.map((agent, index) => (
                      <tr key={agent.Id} style={{ backgroundColor: 'white' }}>
                        <td style={columnStyle}>{agent.Id}</td>
                        <td style={columnStyle}>{index + 1}</td>
                        <td style={columnStyle}>
                          <Form.Control 
                            as="select" 
                            value={agent.StartTime || ""}
                            onChange={(e) => handleShiftStartChange('pm', agent.Id, e.target.value)}
                          >
                            <option value="" disabled>
                              Select Shift Start
                            </option>
                            {pmShiftOptions.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                        <td style={columnStyle}>
                          <Form.Control 
                            as="select" 
                            value={agent.EndTime || ""}
                            onChange={(e) => handleShiftEndChange('pm', agent.Id, e.target.value)}
                          >
                            <option value="" disabled>
                              Select Shift End
                            </option>
                            {pmShiftOptions.map((option, idx) => (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            ))}
                          </Form.Control>
                        </td>


                        <td style={columnStyle}>
                          <Select
                            options={agents}
                            placeholder="Select Agent"
                            value={agents.find(agentOption => agentOption.value === agent.AgentId)}
                            onChange={(selectedOption) => handleAgentChange('pm', agent.Id, selectedOption)}
                          />
                        </td>                      
                        <td style={columnStyle}>
                          <Select
                            isMulti
                            options={campaigns}
                            placeholder="Select Status"
                            value={campaigns.filter(campaignOption => (agent.AssignmentStatus || []).includes(campaignOption.value))} 
                            onChange={(selectedOptions) => handleStatusChange('pm', agent.Id, selectedOptions)}
                          />
                        </td>


                        <td style={columnStyle}>
                          <div style={iconStyle} onClick={() => toggleCheckbox(`action${agent.Id}`)}>
                            {checkboxStates[`action${agent.Id}`] ? <FaRegDotCircle style={{ color: '#de01ff' }} /> : <FaRegCircle style={{ color: '#a2a9b2' }} />}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer style={{ textAlign: 'right', padding: '1rem 1rem' }}>
              <Button variant="secondary" style={{ marginRight: '1rem', backgroundColor: 'white', border: '1px solid #cbcbcb', borderRadius: '10px', margin: '2px' }} onClick={handleCancel}>Cancel</Button>
              <Button variant="primary" style={{ backgroundColor: '#6841c7', borderRadius: '10px', margin: '2px' }} onClick={handleSaveChanges}>Save Changes</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>


      {/* Modal for adding agents */}

      <Modal show={showAddAgentModal} onHide={() => setShowAddAgentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ width: '100%', textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
            Add Shift
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '1rem' }}>Choose a Shift</p>
          <Button variant="success" onClick={() => addShiftRow('A.M.')} style={{ borderRadius: '10px', border: '1px solid #ced4da', marginRight: '10px' }}>A.M.</Button>
          <Button variant="success" onClick={() => addShiftRow('P.M.')} style={{ borderRadius: '10px', border: '1px solid #ced4da', marginRight: '10px' }}>P.M.</Button>      
          <hr style={{ margin: '20px 0' }} />
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '10px', marginTop: '-1.5rem' }}>
          <Button variant="secondary" onClick={() => setShowAddAgentModal(false)} style={{ borderRadius: '10px', border: '1px solid #ced4da', marginRight: '1.5rem' }}>Cancel</Button>
        </Modal.Footer>
      </Modal>


      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton style={{ padding: '20px 35%' }}>
          <Modal.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={DeleteEvent} alt="Delete Agent Schedule" style={{ height: '5rem', width: '5rem', marginBottom: '5px' }} />
              <div style={{ color: 'black', fontWeight: 'bold' }}>Delete Schedule</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
            <p>Are you sure you want to delete this schedule?</p>
            <p>This action cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
            style={{ borderRadius: '10px', border: '1px solid #ced4da', marginRight: '10px' }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={deleteSchedule}
            style={{ backgroundColor: 'red', color: 'white', borderRadius: '10px' }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ScheduleAgentScheduleAdmin;