import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import axios from 'axios';

import NoPhoneCalls from "../../assets/icons/NoPhoneCalls.png";
import NoMessageSent from "../../assets/icons/NoMessageSent.png";
import NoEmailsSent from "../../assets/icons/NoEmailsSent.png";
import NoContactReachTotal from "../../assets/icons/NoContactReachTotal.png";
import NoDQ from "../../assets/icons/NoDQ.png";
import NoApptSet from "../../assets/icons/NoApptSet.png";
import NoRetainers from "../../assets/icons/NoRetainers.png";
import NoDocsSent from "../../assets/icons/NoDocsSent.png";
import NoofShowedAppts from "../../assets/icons/NoofShowedAppts.png";
import NoofRetainersSet from "../../assets/icons/NoofRetainersSet.png";
import NoofLiveTransfersSent from "../../assets/icons/NoofLiveTransfersSent.png";
import DatePicker from 'react-datepicker';
import { FaCalendar } from 'react-icons/fa';

import "./databaseanalytics.css";

const CustomDatePicker = ({ selected, onChange, placeholder, minDate, popperPlacement }) => (
  <div className="datepicker-wrapper">
    <label>{placeholder}:</label>
    <div className="custom-datepicker-input">
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="MM/dd/yyyy"
        placeholderText={`Select ${placeholder}`}
        customInput={<CustomInput />}
        minDate={minDate}
        popperPlacement={popperPlacement}
      />
    </div>
  </div>
);

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <FaCalendar className="calendar-icon" />
    {value}
  </div>
));

function renderCard(title, icon, value) {
  const isLiveTransfersCard = title === "# of Live Transfers Sent";
  const cardStyle = { height: '122px', maxHeight: '200px' };
  const titleStyle = isLiveTransfersCard ? { fontSize: '14px' } : {};

  return (
    <Col lg="2">
      <Card className="card-chart bg-white card-with-box-shadow" style={cardStyle}>
        <CardHeader>
          <div className="card-header-content">
            <img src={icon} alt={title} className="card-icon" style={{ marginBottom: '20px' }} />
            <div>
              <h5 className="card-category" style={titleStyle}>{title}</h5>
              <CardTitle tag="h3" className="card-value">
                {value}
              </CardTitle>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {/* Content for the card */}
        </CardBody>
      </Card>
    </Col>
  );
}

function Analytics() {
  const [analyticsData, setAnalyticsData] = useState(null);

  const today = new Date();
  const past30Days = new Date(today);
  past30Days.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState(past30Days);
  const [endDate, setEndDate] = useState(today);

  useEffect(() => {
    const start = past30Days.toISOString().split('T')[0];
    const end = today.toISOString().split('T')[0];
    fetchData(start, end);
  }, []);

  const fetchData = async (startDate, endDate) => {
    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetAnalytics?startDate=${startDate}&endDate=${endDate}`);

      console.log('fetchData > response: ', response.data.data);
      
      if (response.data) {
        setAnalyticsData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);

      let tempDate = date.toISOString().split('T')[0];
      fetchData(tempDate, tempDate);
    } else {
      let tempStartDate = date.toISOString().split('T')[0];
      let tempEndDate = endDate.toISOString().split('T')[0];
      fetchData(tempStartDate, tempEndDate);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    let tempStartDate = startDate.toISOString().split('T')[0];
    let tempEndDate = date.toISOString().split('T')[0];
    fetchData(tempStartDate, tempEndDate);
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem', marginLeft: '1rem' }}>
      <Row>
        <h3 style={{ color: '#101828', marginLeft: '1rem', marginBottom: '1rem' }}>Intake Analytics</h3>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
            <CustomDatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              placeholder="From"
            />
            <CustomDatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              placeholder="To"
              minDate={startDate}
              popperPlacement="bottom-end"
            />
        </Col>
      </Row>

      <br />

      {/* First Row */}
      <Row>
        {analyticsData && (
          <>
            {renderCard("# Phone Calls", NoPhoneCalls, analyticsData.PhoneCalls)}
            {renderCard("# Message Sent", NoMessageSent, analyticsData.MessagesSent)}
            {renderCard("# Emails Sent", NoEmailsSent, analyticsData.EmailsSent)}
            {renderCard("# Contact Reach", NoContactReachTotal, analyticsData.ContactsReachTotal)}
            {renderCard("# DQs", NoDQ, analyticsData.Disqualifieds)}
            {renderCard("# Appt Set", NoApptSet, analyticsData.ApptsSet)}
          </>
        )}
      </Row>
      {/* Second Row */}
      <Row>
        {analyticsData && (
          <>
            {renderCard("# Retainers", NoRetainers, analyticsData.Retainers)}
            {renderCard("# Docs Sent", NoDocsSent, analyticsData.DocsSent)}
            {renderCard("# of Showed Appts", NoofShowedAppts, analyticsData.ShowedAppts)}
            {renderCard("# of Retainers Set", NoofRetainersSet, analyticsData.RetainersSet)}
            {renderCard("# of Live Transfers Sent", NoofLiveTransfersSent, analyticsData.LiveTransfersSent)}
          </>
        )}
      </Row>
    </div>
  );
}

export default Analytics;