import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';

const ScheduleAgentScheduleGS = () => {
  const [selectedSheet, setSelectedSheet] = useState('0');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storedIsAdmin = localStorage.getItem('isAdmin');
    setIsAdmin(storedIsAdmin === 'true');
  }, []);

  const handleSheetChange = (event) => {
    setSelectedSheet(event.target.value);
  };

  const baseUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR-Sc2Z_Re9FjC_d6_M-aqOjH5BJbfYgsvWn7IbeAaQXjm-pQOCi8XTteWTu955e0QPG7m_FFGgjJsF/pubhtml';

  const newTabLink = 'https://docs.google.com/spreadsheets/d/1zldDImLI_qpzpp5o1ixTd2lAhabX6Yy_hFry4zQbSbs/edit?pli=1&gid=1175413394#gid=1175413394';

  const openInNewTab = () => {
    window.open(newTabLink, '_blank');
  };

  return (
    <Card style={{ width: '95%', marginLeft: '5rem', marginTop: '2rem' }}>
      <Card.Body>
        <Card.Title>Google Sheet Schedule</Card.Title>
        <Card.Text>
          View and manage your schedule directly from the embedded Google Sheet below.
        </Card.Text>
        {isAdmin && (
          <Button variant="primary" onClick={openInNewTab} style={{ marginBottom: '1rem' }}>
            Open Google Sheet in New Tab
          </Button>
        )}

        <iframe
          src={`${baseUrl}?gid=${selectedSheet}&widget=true&headers=false`}
          width="100%"
          height="520px"
          frameBorder="0"
          title="Google Sheet"
          style={{ borderRadius: '5px', border: '1px solid #ddd' }}
        />
      </Card.Body>
    </Card>
  );
};

export default ScheduleAgentScheduleGS;
