import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import moment from "moment";
import 'moment/locale/en-gb';
import axios from "axios";
import Swal from 'sweetalert2';
import DeleteEvent from "../../../assets/icons/delete-event.png";
import AgentsList from "./AgentsList.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./waterfall.css";
import "../waterfall-main.css";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/themes/base/all.css';
import requestSchedule from "../../../assets/icons/requestschedule.png";

moment.locale("en-US");
const localizer = momentLocalizer(moment);

localizer.formats = {
  ...localizer.formats,
  timeGutterFormat: 'HH:mm',
  eventTimeRangeFormat: ({ start, end }) => {
    const format = 'HH:mm';
    const startTime = moment(start).format(format);
    const endTime = moment(end).format(format);
    const formattedStartTime = startTime === '12:00 AM' ? '' : startTime;
    const formattedEndTime = endTime === '12:00 AM' ? '' : endTime;

    return `${formattedStartTime} - ${formattedEndTime}`;
  },
};

const customizeDateHeader = () => {
  let head = undefined;
  let tail = undefined;
  let xIndex = -1;
  let parentButton = undefined;

  $('button.rbc-button-link > span').each(function(i, obj) {
    parentButton = $(this).parent();
    $(parentButton).children().remove('div');

    xIndex = $(this).text().indexOf(' ');
    head = $(this).text().substring(0, xIndex).toUpperCase();
    tail = $(this).text().substring(xIndex).trim();
    $(parentButton).append(`<div><div><span>${head}</span></div><div><span>${tail}</span></div></div>`);
  });
};

const CoverageWaterfall = ({ onAgentClick, selectedCampaignId, selectedFirmId }) => {
  const [events, setEvents] = useState([]);
  const [currentWeek, setCurrentWeek] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEventToDelete, setSelectedEventToDelete] = useState(null);
  const [isAgentsListVisible, setIsAgentsListVisible] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const scrollToTime = moment().startOf('day').add(8, 'hours').toDate();

  const [isChangeScheduleClicked, setIsChangeScheduleClicked] = useState(false);
  const [showChangeScheduleRequestModal, setShowChangeScheduleRequestModal] = useState(false);
  const [selectedReqScheduleDate, setSelectedReqScheduleDate] = useState('');

  const emptyFormData = {
    Id: 0,
    OriginalScheduleDate: '',
    OriginalStartTime: '',
    OriginalEndTime: '',
    RequestedScheduleDate: '',
    RequestedStartTime: '',
    RequestedEndTime: '',
  };

  const [formData, setFormData] = useState(emptyFormData);

  useEffect(() => {
    customizeDateHeader();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedCampaignId, selectedFirmId, currentWeek]);

  useEffect(() => {
    const func = async () => {
      if (selectedEvent && isChangeScheduleClicked) {
        const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetSchedule?Id=${selectedEvent.id}`);
  
        if (response.data.data) {
          setFormData({ 
            Id: response.data.data[0].Id,
            OriginalScheduleDate: response.data.data[0].OriginalScheduleDate,
            OriginalStartTime: response.data.data[0].OriginalStartTime,
            OriginalEndTime: response.data.data[0].OriginalEndTime,
            RequestedScheduleDate: response.data.data[0].RequestedScheduleDate,
            RequestedStartTime: response.data.data[0].RequestedStartTime,
            RequestedEndTime: response.data.data[0].RequestedEndTime,
          });
          setShowChangeScheduleRequestModal(true);
        }

        setSelectedEvent(null);
        setIsChangeScheduleClicked(false);
      } else if (selectedEvent) {
        setSelectedEventToDelete(selectedEvent);
        setShowDeleteModal(true);

        setSelectedEvent(null);
      }
    };
    func();
  }, [selectedEvent, isChangeScheduleClicked]);

  useEffect(() => {
    setFormData({
      ...formData,
      RequestedScheduleDate: selectedReqScheduleDate,
    });
  }, [selectedReqScheduleDate]);

  useEffect(() => {
    if (showChangeScheduleRequestModal) applyDatePicker();
  }, [showChangeScheduleRequestModal]);

  const fetchData = async () => {
    try {
      const campaignId = selectedCampaignId ?? 0;
      const firmId = selectedFirmId ?? 0;
     
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetSchedulesByWeek?startDate=${moment(currentWeek.startDate).format('YYYY-MM-DD')}&endDate=${moment(currentWeek.endDate).format('YYYY-MM-DD')}&campaignId=${campaignId}&firmId=${firmId}`);

      let data = response.data.data;

      customizeDateHeader();

      if (response.data.errorMessage === "Records not found") {
        Swal.fire({
          icon: 'warning',
          title: 'No Agent Scheduled',
          text: "This week does not have any agent plots scheduled.",
        });
        return;
      }

      const isAdmin = localStorage.getItem('isAdmin') === 'false';
      const agentId = isAdmin ? localStorage.getItem('id') : null;

      if (isAdmin && agentId) {
        data = data.filter(event => event.AgentId === parseInt(agentId));

        if (data.length === 0) {
          Swal.fire({
            icon: 'warning',
            title: 'No Agent Scheduled',
            text: "The selected agent does not have a plotted schedule for this week.",
          });
          return;
        }
      }

      const newEvents = data.map(event => {
        const startTime = moment(event.StartTime, 'h:mm A').toDate();
        const endTime = moment(event.EndTime, 'h:mm A').toDate();
        const startDate = new Date(event.ScheduleDate);
        const endDate = new Date(event.ScheduleDate);
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
        startDate.setHours(startTime.getHours(), startTime.getMinutes());
        endDate.setHours(endTime.getHours(), endTime.getMinutes());

        return {
          id: event.Id,
          AgentName: event.AgentName,
          CampaignName: event.CampaignName,
          start: startDate,
          end: endDate,
          color: getColorByCampaign(event.CampaignName),
        };
      });

      setEvents(newEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteEvent = async () => {
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin === 'true') {
      if (selectedEventToDelete) {
        setShowDeleteModal(false);
        Swal.fire({
          title: 'Are you sure?',
          text: 'Please type YES to confirm deletion',
          input: 'text',
          inputPlaceholder: 'Type YES',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          inputValidator: (value) => {
            if (value !== 'YES') {
              return 'You need to type YES to proceed'
            }
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await axios.delete(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/DeleteSchedule?Id=${selectedEventToDelete.id}`);
              setShowDeleteModal(false);
              setSelectedEventToDelete(null);
              fetchData();

              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Event deleted successfully!',
              });
            } catch (error) {
              console.error('Error deleting event:', error);

              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete event. Please try again later.',
              });
            }
          } else {
            setShowDeleteModal(true);
          }
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized!',
        text: 'You are not authorized to delete events.',
      });
    }
  };

  const getColorByCampaign = (campaignName) => {
    switch (campaignName) {
      case 'APAP':
        return '#FEF789';
      case 'B2B':
        return '#CDA7ED';
      case 'CAMP':
        return '#FECDCA';
      case 'Camp Lejeune':
        return '#FECDCA';
      case 'NEC':
        return '#B2DDFF';
      case 'MVA':
        return '#ABEFC6';
      case 'TALC':
        return '#F2D5AE';
      default:
        return '#F3F8FF';
    }
  };

  const handleSubmitChangedSchedule = async () => {

    if (!changeScheduleRequestValidation()) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error found in your input. Please correct.',
      });
    } else {
      const result = await axios.post('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/PostUpdateEditedSchedule', formData);

      if (result.data.success) {
        await handleChangeScheduleModalClose();
        Swal.fire({
          icon: 'success',
          title: 'Successful',
          text: 'New schedule request has been submitted for approval!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error: Submission of new schedule request has failed.',
        });
      }
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const darkenedColor = darkenColor(event.color, 20);

    return {
      style: {
        backgroundColor: event.color,
        color: 'gray',
        borderLeft: `5px solid ${darkenedColor}`,
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEventClick = async (event) => {
    setSelectedEvent(event);
  };

  const handleChangeScheduleButtonClick = async () => {
    setIsChangeScheduleClicked(true);
  };

  const handleChangeScheduleModalClose = async () => {
    setShowChangeScheduleRequestModal(false);
  };

  const darkenColor = (color, percent) => {
    let num = parseInt(color.replace("#",""), 16),
        amt = Math.round(1.55 * percent),
        R = (num >> 16) - amt,
        G = (num >> 8 & 0x00FF) - amt,
        B = (num & 0x0000FF) - amt;
    return "#" + (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
    ).toString(16).slice(1);
  };

  const handleNavigate = (newDate, view) => {
    if (view === 'week') {
      const startDate = moment(newDate).startOf('week').toDate();
      const endDate = moment(newDate).endOf('week').toDate();
      setCurrentWeek({ startDate, endDate });
    }
  };

  const EventComponent = ({ event }) => (
    <div style={{height: '100%'}}>
      <div className="event-info-2" style={{minHeight: '24%'}}>
        <div style={{fontSize: 'x-small', paddingTop: '1px'}}>EST</div>
        <div style={{fontSize: 'small', fontWeight: 'bold', paddingTop: '1px'}}>{event.AgentName}</div>
        <div style={{ fontSize: 'x-small', color: 'gray', paddingTop: '1px'}}>{event.CampaignName}</div>
      </div>
      <div className="event-info-3" style={{alignContent: 'end', height: '73%'}}><img src={requestSchedule} onClick={handleChangeScheduleButtonClick} alt="Change Schedule Request Icon" style={{ paddingRight: '5px', paddingBottom: '2px' }} /></div>
    </div>
  );

  const toggleAgentsListVisibility = () => {
    setIsAgentsListVisible(!isAgentsListVisible);
  };

  const applyDatePicker = () => {
    $('#RequestedScheduleDate').datepicker({
      dateFormat: 'mm/dd/yy',
      onSelect: function (input2) {
        setSelectedReqScheduleDate(input2);
      }
    });
  }

  const changeScheduleRequestValidation = () => {
    let isValid = true;

    if (!formData.RequestedScheduleDate) isValid = false;
    else if (!formData.RequestedStartTime) isValid = false;
    else if (!formData.RequestedEndTime) isValid = false;

    return isValid;
  };

  const formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd DD', culture),
  }

  return (
    <Container fluid style={{ marginTop: '2rem', marginBottom: '3rem', marginLeft: '3rem' }}>
      <Row>
        <Col md={isAgentsListVisible ? 9 : 12}>
          <Container fluid className="WaterFallContainer">
            <Calendar
              formats={formats}
              localizer={localizer}
              events={events}
              defaultView="week"
              views={['week']}
              style={{ height: 775 }}
              eventPropGetter={eventStyleGetter}
              onNavigate={handleNavigate}
              onSelectEvent={handleEventClick}
              components={{
                event: EventComponent,
              }}
              scrollToTime={scrollToTime}
              timeslots={4}
            />
          </Container>
        </Col>
        {isAgentsListVisible && (
          <Col md={3} style={{ marginTop: '18px' }}>
            <AgentsList onAgentClick={onAgentClick} currentWeek={currentWeek} />
          </Col>
        )}
      </Row>
      <Button
        onClick={toggleAgentsListVisibility}
        style={{
          position: 'absolute',
          top: '6%',
          right: isAgentsListVisible ? '2%' : '2%',
          color: '#525f7f',
          backgroundColor: 'transparent',
          zIndex: 800,
          transform: 'translateY(-50%)'
        }}
      >
        {isAgentsListVisible ? 'Hide ' : 'Show '}
        <FontAwesomeIcon icon={isAgentsListVisible ? faChevronRight : faChevronLeft} />
      </Button>
      {/* Delete event modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton style={{ padding: '20px 30%' }}>
          <Modal.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img src={DeleteEvent} alt="Delete Agent Schedule" style={{ height: '5rem', width: '5rem', marginBottom: '5px' }} />
              <div style={{ color: 'black', fontWeight: 'bold' }}>Delete Agent Schedule</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          Are you sure you want to delete the event "{selectedEventToDelete && selectedEventToDelete.AgentName}"? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer style={{ marginBottom: '10px' }}>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)} style={{ borderRadius: '10px', border: '1px solid #ced4da' }}>Cancel</Button>
          <Button variant="danger" onClick={handleDeleteEvent} style={{ marginRight: '20px', backgroundColor: 'red', color: 'white', borderRadius: '10px' }}>Delete</Button>
        </Modal.Footer>
      </Modal>
      
      {/* Change Schedule Request modal */}
      <Modal id="change-schedule-request-modal" show={showChangeScheduleRequestModal}>
        <Modal.Header closeButton>
          <Modal.Title style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ color: 'black', fontWeight: 'bold' }}>Change Schedule Request</div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ textAlign: 'center' }}>
          <Form className="container">
            <Form.Group style={{ display: 'grid', textAlign: 'left', paddingBottom: '10px' }}>
              <Form.Label style={{ paddingBottom: '5px' }}>Original Schedule Date</Form.Label>
              <input id="OriginalScheduleDate" name="OriginalScheduleDate" 
              defaultValue={formData.OriginalScheduleDate} 
              className="form-control h4" 
              
              type="text" disabled />
            </Form.Group>
            <Form.Group style={{ textAlign: 'left', paddingBottom: '10px' }} className="row">
              <div className="col" style={{ width: '50%' }}>
                <Form.Label style={{ paddingBottom: '5px' }}>From</Form.Label>
                <Form.Control
                  type="time"
                  name="OriginalStartTime"
                  value={formData.OriginalStartTime}
                  className="h4"
                  disabled
                />
              </div>
              <div className="col" style={{ width: '50%' }}>
                <Form.Label style={{ paddingBottom: '5px' }}>To</Form.Label>
                <Form.Control
                  type="time"
                  name="OriginalEndTime"
                  value={formData.OriginalEndTime}
                  className="h4"
                  disabled
                />
              </div>
            </Form.Group>
            <Form.Group style={{ display: 'grid', textAlign: 'left', paddingBottom: '10px' }}>
              <Form.Label style={{ paddingBottom: '5px' }}>Requested Schedule Date</Form.Label>
              <input id="RequestedScheduleDate" name="RequestedScheduleDate" value={formData.RequestedScheduleDate} onChange={() => {}} className="form-control h4" type="text" />
            </Form.Group>
            <Form.Group style={{ textAlign: 'left', paddingBottom: '10px' }} className="row">
              <div className="col" style={{ width: '50%' }}>
                <Form.Label style={{ paddingBottom: '5px' }}>From</Form.Label>
                <Form.Control
                  type="time"
                  name="RequestedStartTime"
                  value={formData.RequestedStartTime}
                  onChange={handleChange}
                  className="h4"
                />
              </div>
              <div className="col" style={{ width: '50%' }}>
                <Form.Label style={{ paddingBottom: '5px' }}>To</Form.Label>
                <Form.Control
                  type="time"
                  name="RequestedEndTime"
                  value={formData.RequestedEndTime}
                  onChange={handleChange}
                  className="h4"
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer style={{ paddingBottom: '30px' }}>
          <div className="container">
            <div className="row">
              <div style={{ width: '50%', textAlign: 'right' }} className="col">
                <Button variant="secondary" onClick={handleChangeScheduleModalClose} style={{ borderRadius: '10px', border: '1px solid #ced4da', width: '90%' }}>Cancel</Button>
              </div>
              <div style={{ width: '50%', textAlign: 'left' }} className="col">
                <Button variant="danger" onClick={handleSubmitChangedSchedule} style={{ backgroundColor: '#6941c6', color: 'white', borderRadius: '10px', width: '90%' }}>Save</Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CoverageWaterfall;