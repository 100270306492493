import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import DatabaseNavigation from './DatabaseNavigation';
import './database.css';
import './Daterange.css';

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Time Frame',
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        text: 'Retainers',
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      title: {
        display: true,
        text: 'Output',
      },
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const DatabaseOutput = ({ selectedAgent, selectedCampaign, timeFrame, onAgentChange, onCampaignChange, onTimeFrameChange }) => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [agents, setAgents] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchAgents();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (selectedAgent && selectedCampaign) {
      fetchChartData(timeFrame, selectedAgent, selectedCampaign);
    }
  }, [timeFrame, selectedAgent, selectedCampaign]);

  const fetchAgents = async () => {
    try {
      const token = localStorage.getItem('ViribuzAccessToken');
      if (!token) {
        console.error('No access token found in localStorage');
        return;
      }

      const excludedEmails = [
        'admin@mail.com',
        'casper@mail.com',
        'agent@mail.com',
        'testagentx@agent.com',
        'testagenty@agent.com',
        'string',
        'rogeradmin@agent.com'
      ];

      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgent/GetAgents', {
        headers: {
          'ViribuzAccessToken': token,
        }
      });

      const filteredAgents = response.data.data
        .filter(agent => !agent.isAdmin)
        .filter(agent => !excludedEmails.includes(agent.email));

      setAgents(filteredAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetCampaigns');
      const sortedCampaigns = response.data.campaigns.sort((a, b) => a.name.localeCompare(b.name));
      const allCampaignOption = { id: '0', name: 'All Campaigns' };
      setCampaigns([allCampaignOption, ...sortedCampaigns]);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const getDateRange = (timeFrame) => {
    const today = moment();
    let fromDate, toDate;

    if (timeFrame === 'months') {
      fromDate = moment().month(4).startOf('month').format('YYYY-MM-DD');
      toDate = moment().month(7).endOf('month').format('YYYY-MM-DD');
    } else if (timeFrame === 'weeks') {
      fromDate = today.clone().subtract(3, 'weeks').startOf('week').format('YYYY-MM-DD');
      toDate = today.clone().endOf('week').format('YYYY-MM-DD');
    } else if (timeFrame === 'days') {
      fromDate = today.clone().startOf('month').format('YYYY-MM-DD');
      toDate = today.clone().endOf('month').format('YYYY-MM-DD');
    }

    return { fromDate, toDate };
  };

  const fetchChartData = async (timeFrame, agentId, campaignId) => {
    const { fromDate, toDate } = getDateRange(timeFrame);

    try {
      const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentDatabase/GetLineChartByDateRange?DateFrom=${fromDate}&DateTo=${toDate}&agentId=${agentId}&campaignId=${campaignId}`);
      let data = response.data;

      if (!data || data.length === 0) {
        setChartData({
          labels: [],
          datasets: [],
        });
        return;
      }

    if (timeFrame === 'weeks') {
        const groupedData = {};
        const weekLabels = [];

        data.forEach(item => {
            const retainedDate = moment(item.RetainedDate);
            if (retainedDate.isValid()) {
                const year = retainedDate.year();
                const week = retainedDate.week();

                const weekKey = `Week ${week} (${year})`;

                if (!groupedData[weekKey]) {
                    groupedData[weekKey] = { output: 0, retainers: 0 };
                }

                groupedData[weekKey].output += item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent;
                groupedData[weekKey].retainers += item.TotalRetainersAcquired;
            }
        });

        const sortedWeekKeys = Object.keys(groupedData).sort((a, b) => {
            const [aWeek, aYear] = a.match(/Week (\d+) \((\d+)\)/).slice(1).map(Number);
            const [bWeek, bYear] = b.match(/Week (\d+) \((\d+)\)/).slice(1).map(Number);

            if (aYear === bYear) return aWeek - bWeek;
            return aYear - bYear;
        });

      const output = sortedWeekKeys.map(key => groupedData[key].output);
        const retainers = sortedWeekKeys.map(key => groupedData[key].retainers);
        const finalWeekLabels = sortedWeekKeys.map(key => key);

        setChartData({
            labels: finalWeekLabels,
            datasets: [
                {
                    type: 'line',
                    label: 'Retainers',
                    data: retainers,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: 'Output',
                    data: output,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    yAxisID: 'y1',
                },
            ],
        });
      } else if (timeFrame === 'months') {
        const months = ['May', 'June', 'July', 'August'];
        const groupedData = {
          May: { output: 0, retainers: 0 },
          June: { output: 0, retainers: 0 },
          July: { output: 0, retainers: 0 },
          August: { output: 0, retainers: 0 },
        };

        data.forEach(item => {
          const month = moment(item.RetainedDate).format('MMMM');
          if (groupedData[month]) {
            groupedData[month].output += item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent;
            groupedData[month].retainers += item.TotalRetainersAcquired;
          }
        });

        const output = months.map(month => groupedData[month].output);
        const retainers = months.map(month => groupedData[month].retainers);

        setChartData({
          labels: months,
          datasets: [
            {
              type: 'line',
              label: 'Retainers',
              data: retainers,
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              type: 'bar',
              label: 'Output',
              data: output,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });
      } else {
        const labels = Array.from(new Set(data.map(item => new Date(item.RetainedDate).toLocaleDateString())));
        const output = labels.map(label => data.filter(item => new Date(item.RetainedDate).toLocaleDateString() === label).reduce((sum, item) => sum + item.TotalPhoneCallsMade + item.TotalMessagesSent + item.TotalEmailsSent, 0));
        const retainers = labels.map(label => data.filter(item => new Date(item.RetainedDate).toLocaleDateString() === label).reduce((sum, item) => sum + item.TotalRetainersAcquired, 0));

        setChartData({
          labels,
          datasets: [
            {
              type: 'line',
              label: 'Retainers',
              data: retainers,
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              yAxisID: 'y',
            },
            {
              type: 'bar',
              label: 'Output',
              data: output,
              backgroundColor: 'rgba(75, 192, 192, 0.5)',
              yAxisID: 'y1',
            },
          ],
        });
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartData({
        labels: [],
        datasets: [],
      });
    }
  };


  return (
    <div className="container-fluid" style={{ height: '50vh', paddingBottom: '1rem' }}>
      <Container fluid style={{ paddingBottom: '1rem' }}>
        <h3 style={{ color: '#a2a9b2' }}>Retainers Vs. Output Over Time</h3>
        <Row>
          <Col>
            <Bar options={options} data={chartData} width={250} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DatabaseOutput;
