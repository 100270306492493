import React, { useState, useEffect } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';

import './coverageschedulelog.css';

const ScheduleLog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState({ field: '', order: '' });
  const pageSize = 25;

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentWaterfallCoverage/GetChangeScheduleRequestsList?campaignId=0&firmId=0');
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sortData = (field) => {
    const order = sortBy.field === field && sortBy.order === 'asc' ? 'desc' : 'asc';
    setData([...data.sort((a, b) => {
      if (order === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    })]);
    setSortBy({ field, order });
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return data.slice(startIndex, endIndex).map((row, index) => {
      const originalScheduledDate = new Date(row.OriginalScheduleDate);
      const formattedOriginalScheduledDate = `${originalScheduledDate.getMonth() + 1}/${originalScheduledDate.getDate()}/${originalScheduledDate.getFullYear()}`;
      const requestedDate = new Date(row.RequestedScheduleDate);
      const formattedRequestedDate = `${requestedDate.getMonth() + 1}/${requestedDate.getDate()}/${requestedDate.getFullYear()}`;
      const originalStartTime = new Date(row.OriginalStartTime);
      const originalEndTime = new Date(row.OriginalEndTime);
      const requestedStartTime = new Date(row.RequestedStartTime);
      const requestedEndTime = new Date(row.RequestedEndTime);

      return (
        <tr key={index}>
          <td>{row.AgentName}</td>
          <td>{formattedOriginalScheduledDate}</td>
          <td>{originalStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
          <td>{originalEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
          <td>{formattedRequestedDate}</td>
          <td>{requestedStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
          <td>{requestedEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
          <td style={{ color: row.IsApproved ? 'green' : 'red' }}>{row.IsApproved ? 'Approved' : 'Rejected'}</td>
        </tr>
      );
    });
  };

  const totalPages = Math.ceil(data.length / pageSize);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '5rem', marginTop: '2rem', marginBottom: '3rem' }}>
      <Card className="mb-4 bg-white" style={{ borderTop: '3px solid #7b50d5', color: 'black' }}>
        <Card.Header>
          <h5 className="card-title">Change Schedule Request Log</h5>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => sortData('AgentName')}>Agent Name</th>
                <th onClick={() => sortData('OriginalScheduleDate')}>Original Scheduled Date</th>
                <th onClick={() => sortData('OriginalStartTime')}>From</th>
                <th onClick={() => sortData('OriginalEndTime')}>To</th>
                <th onClick={() => sortData('RequestedScheduleDate')}>Requested Date</th>
                <th onClick={() => sortData('RequestedStartTime')}>From</th>
                <th onClick={() => sortData('RequestedEndTime')}>To</th>
                <th onClick={() => sortData('IsApproved')}>Approve/Reject</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Page <span id="currentPage">{currentPage}</span> of <span id="totalPages">{totalPages}</span>
            </div>
            <nav aria-label="Page navigation" className="d-flex justify-content-end">
              <ul className="pagination">
                <li className="page-item" onClick={handlePrevPage}>
                  <button className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo; Previous</span>
                  </button>
                </li>
                <li className="page-item" onClick={handleNextPage}>
                  <button className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">Next &raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ScheduleLog;
