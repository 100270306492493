import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import axios from "axios";
import { Container, Dropdown } from "react-bootstrap";
import maindaterange from "../../assets/icons/maindaterange.png";
import "./maincalendar.css";
import "./calendar.css";

const renderEventContent = ({ event }) => {
  const eventStyle = {
    marginBottom: '1px',
    fontSize: '14px',
    border: `1px solid ${event.borderColor}`,
    borderRadius: '3px',
    paddingLeft: '8px',
    width: '100%',
    display: event.extendedProps.isExtra ? 'none' : 'inline-block',
    marginRight: '5px',
    whiteSpace: 'nowrap',
    backgroundColor: event.backgroundColor,
  };

  const timeContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px',
    color: '#777',
    paddingLeft: '3px',
    paddingRight: '8px',
  };

  const fontColorStyle = {
    fontWeight: 'bold',
    color: event.extendedProps.fontColor,
  };

  return (
    <div style={eventStyle} className="event-content">
      <div style={timeContainerStyle}>
        <div style={fontColorStyle}>{event.extendedProps.formattedName}</div>
        <div style={fontColorStyle}>{event.extendedProps.TotalWorkedHours}</div>
      </div>
    </div>
  );
};

function MainCalendar() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);

  const campaignColors = {
    APAP: {
      borderColor: "#CBC66E",
      backgroundColor: "#FEF789",
      fontColor: "#B54708",
    },
    B2B: {
      borderColor: "#A486BE",
      backgroundColor: "#CDA7ED",
      fontColor: "#6841c7",
    },
    CAMP: {
      borderColor: "#CBA4A2",
      backgroundColor: "#FECDCA",
      fontColor: "#B42318",
    },
    "Camp Lejeune": {
      borderColor: "#CBA4A2",
      backgroundColor: "#FECDCA",
      fontColor: "#B42318",
    },
    NEC: {
      borderColor: "#8EB1CC",
      backgroundColor: "#B2DDFF",
      fontColor: "#175CD3",
    },
    "NEC Acquisition": {
      borderColor: "#6C9BCC",
      backgroundColor: "#B0D3FF",
      fontColor: "#003D6B",
    },
    "Round Up": {
      borderColor: "#F2A54A",
      backgroundColor: "#FBE4D4",
      fontColor: "#C65B2F",
    },
    MVA: {
      borderColor: "#89BF9E",
      backgroundColor: "#ABEFC6",
      fontColor: "#067647",
    },
    TALC: {
      borderColor: "#C1AA8B",
      backgroundColor: "#F2D5AE",
      fontColor: "#f0a84b",
    },
  };

  const convertDecimalToHHMM = (decimalHours) => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    console.log(`Decimal Hours: ${decimalHours} -> Total Minutes: ${totalMinutes} -> HH:MM: ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data for year:", selectedYear, "month:", selectedMonth);
        setEvents([]);

        const response = await axios.get(`http://statistics-staging.viribuzmedia.com/umbraco/Api/ViribuzAgentMain/GetAgentsDailyHoursInMonthByYearMonthV2?year=${selectedYear}&month=${selectedMonth}`);
        const data = response.data.data;

        console.log("API Response Data:", data);

        const eventMap = {};
        data.forEach(item => {
          const date = item.Date;
          if (!eventMap[date]) {
            eventMap[date] = [];
          }
          eventMap[date].push(item);
        });

        const mappedEvents = [];
        Object.keys(eventMap).forEach(date => {
          const dateEvents = eventMap[date];
          dateEvents.forEach((event, index) => {
            const colorConfig = campaignColors[event.CampaigName] || campaignColors['APAP'];

            const [firstName, lastName] = event.AgentName.split(' ');
            const formattedName = lastName ? `${firstName} ${lastName.charAt(0)}.` : firstName;

            const totalWorkedHoursDecimal = parseFloat(event.TotalWorkedHours);
            console.log("Original TotalWorkedHours:", event.TotalWorkedHours, "Parsed Float:", totalWorkedHoursDecimal);

            const totalWorkedHoursFormatted = convertDecimalToHHMM(totalWorkedHoursDecimal);
            console.log(`Original Hours: ${event.TotalWorkedHours} -> Formatted Hours: ${totalWorkedHoursFormatted}`);

            mappedEvents.push({
              title: `${formattedName} ${totalWorkedHoursFormatted}`,
              start: date,
              extendedProps: {
                formattedName: formattedName,
                TotalWorkedHours: totalWorkedHoursFormatted,
                isExtra: index >= 5,
                fontColor: colorConfig.fontColor,
              },
              borderColor: colorConfig.borderColor,
              backgroundColor: colorConfig.backgroundColor,
              fontColor: colorConfig.fontColor,
            });
          });
        });

        setEvents(mappedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedYear, selectedMonth]);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
  };

  const handleSelect = (info) => {
    setSelectedEvent(null);
  };

  const calendarRef = React.createRef();

  const handleYearChange = (year) => {
    setSelectedYear(parseInt(year));
    const newDate = calendarRef.current.getApi().getDate();
    newDate.setFullYear(year);
    calendarRef.current.getApi().gotoDate(newDate);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(parseInt(month));
    const newDate = calendarRef.current.getApi().getDate();
    newDate.setMonth(month - 1);
    calendarRef.current.getApi().gotoDate(newDate);
  };

  return (
    <>
      <Container fluid style={{ width: '85%', marginTop: '5px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
          <p style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: 'auto', marginRight: '5px' }}>Year</p>
          <Dropdown onSelect={handleYearChange}>
            <Dropdown.Toggle
              variant="info"
              id="dropdown-year"
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '1px solid #cbcbcb',
                borderRadius: '10px',
                margin: '2px',
              }}
            >
              <img src={maindaterange} alt="Range" style={{ paddingRight: '10px' }} />{selectedYear}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={moment().year()}>Current Year</Dropdown.Item>
              <Dropdown.Item eventKey={moment().year() - 1}>{moment().year() - 1}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <p style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>Month</p>
          <Dropdown onSelect={handleMonthChange}>
            <Dropdown.Toggle
              variant="info"
              id="dropdown-month"
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '1px solid #cbcbcb',
                borderRadius: '10px',
                margin: '2px',
              }}
            >
              <img src={maindaterange} alt="Range" style={{ paddingRight: '10px' }} />{moment.months()[selectedMonth - 1]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {moment.months().map((month, index) => (
                <Dropdown.Item key={index + 1} eventKey={index + 1}>
                  {month}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "",
            right: "",
          }}
          firstDay={1}
          dayHeaderFormat={{ weekday: 'long' }}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          selectable={true}
          select={handleSelect}
          dayCellDidMount={(info) => {
            info.el.addEventListener("click", function() {
              const extraEvents = info.el.querySelectorAll('.event-content[style*="display: none"]');
              extraEvents.forEach(event => {
                event.style.display = 'inline-block';
              });
            });
          }}
          dayCellWillUnmount={(info) => {
            info.el.removeEventListener("click", function() {
              const extraEvents = info.el.querySelectorAll('.event-content[style*="display: inline-block"]');
              extraEvents.forEach(event => {
                event.style.display = 'none';
              });
            });
          }}
        />
      </Container>
    </>
  );
}

export default MainCalendar;
