import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';

//Check if the protocol is not HTTP, then redirect
/*if (window.location.protocol !== 'http:') {
  window.location.replace('http://' + window.location.href.substring(window.location.protocol.length));
}*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
