import React from 'react';
import './backgroundimage.css';

const BackgroundImage = () => {
  return (
    <div className="background-image">
      <div className="loader">
        <span className="m">M</span>
        <span className="m">A</span>
        <span className="m">R</span>
        <span className="m">K</span>
        <span className="m">E</span>
        <span className="m">T</span>
        <span className="m">I</span>
        <span className="m">N</span>
        <span className="m">G</span>
        <span className="m">&nbsp;</span>
        <span className="m">M</span>
        <span className="m">U</span>
        <span className="m">S</span>
        <span className="m">C</span>
        <span className="m">L</span>
        <span className="m">E</span>
        <span className="m">&nbsp;</span>
        <span className="m">B</span>
        <span className="m">E</span>
        <span className="m">H</span>
        <span className="m">I</span>
        <span className="m">N</span>
        <span className="m">D</span>
        <span className="m">&nbsp;</span>
        <span className="m">Y</span>
        <span className="m">O</span>
        <span className="m">U</span>
        <span className="m">R</span>
        <span className="m">&nbsp;</span>
        <span className="m">B</span>
        <span className="m">R</span>
        <span className="m">A</span>
        <span className="m">N</span>
        <span className="m">D</span>
      </div>
    </div>
  );
}

export default BackgroundImage;
